import { useTranslate } from "../../../language/i18n";
import PropType from "prop-types";
import { Link } from "react-router-dom";
import { withPage } from "@myloc/myloc-utils";
import { Button, THEME } from "@myloc/myloc-gui";
import ProductCard from "../ProductCard/ProductCard";
import styles from "./ProductCatalogue.module.scss";
import pages from "../../../utils/pages";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";

const ProductCatalogue = ({
  data,
  showFilter,
  productFilters,
  setProductFilters,
  setSearchValue,
  searchValue,
  isLoading,
}) => {
  const translate = useTranslate();
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));

  const clear = filterCategory => {
    let _filters = { ...productFilters };
    if (filterCategory === "includeNotOrderable") {
      _filters[filterCategory].include = false;
    }

    _filters[filterCategory].id = [];

    setProductFilters(_filters);
  };

  const activeFilters = () => {
    if (!productFilters) {
      return [];
    }

    const filters = Object.keys(productFilters).filter(
      category => !!productFilters[category].id?.length || productFilters[category].include,
    );

    return filters.map(filterCategory => (
      <span className={styles.badge} key={filterCategory}>
        {translate(productFilters[filterCategory].title)} ({productFilters[filterCategory].id.length || "1"})
        <button type="button" onClick={() => clear(filterCategory)}>
          ✕
        </button>
      </span>
    ));
  };

  return (
    <>
      <div className={styles.row}>
        <input
          className={styles.input}
          placeholder={translate("SEARCH_FOR_PRODUCT")}
          value={searchValue}
          onChange={event => setSearchValue(event.target.value)}
        />
        <Button theme={THEME.SECONDARY} onClick={() => showFilter(true)}>
          {translate("FILTER")}
        </Button>
      </div>
      <section className={styles.toolBar}>
        <div className={styles.activeFilters}>{activeFilters()}</div>
      </section>
      {isLoading ? (
        <LoadingSpinner title="PRODUCTS_LOADING" />
      ) : (
        <ul className={styles.productList}>
          {!!data?.length &&
            data?.map(product => (
              <li key={product.id} className={styles.listItem}>
                <Link
                  to={{
                    pathname: isEmbedded
                      ? `${pages.EMBEDDED_PRODUCTS.PATH}/${encodeURIComponent(product.id)}`
                      : `${pages.PRODUCTS.PATH}/${encodeURIComponent(product.id)}`,
                    state: { product, referrer: `${window.location.pathname}${window.location.search}` },
                  }}
                  className={styles.productLink}
                >
                  <ProductCard product={product} />
                </Link>
              </li>
            ))}
        </ul>
      )}
    </>
  );
};

ProductCatalogue.propTypes = {
  productFilters: PropType.object,
  setProductFilters: PropType.func,
  showFilter: PropType.func,
  from: PropType.number,
  amount: PropType.number,
  count: PropType.number,
  search: PropType.string,
  searchValue: PropType.string,
  setSearchValue: PropType.func,
  data: PropType.arrayOf(
    PropType.shape({
      description: PropType.string,
      images: PropType.array,
      productNumber: PropType.string,
      quantityDecimals: PropType.number,
      quantity: PropType.number,
      id: PropType.string,
    }),
  ),
  isLoading: PropType.bool,
};

export default withPage(ProductCatalogue);
