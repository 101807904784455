import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { InputField, Button, THEME } from "@myloc/myloc-gui";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import Page from "../shared/Page/Page";
import Paginate from "../shared/Pagination/Paginate";
import styles from "./ReceiveDeliveryPage.module.scss";
import FacilitySelector from "../shared/FacilitySelector/FacilitySelector";
import { useHistory as mylocUseHistory } from "@myloc/myloc-utils";
import { useHistory } from "react-router-dom";
import ReceiveService from "../../services/Receive/ReceiveService";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import Label from "../shared/Label/Label";
import productService from "../../services/product/productService";
import { setError } from "../../reducers/dialog/dialogAction";

const ReceiveDeliveryPage = () => {
  const translate = useTranslate();
  const history = useHistory();

  const userFacility = useSelector(state => state.appData?.user?.facility);
  const params = new URLSearchParams(window.location.search);

  const [baseStorageSelector, setBaseStorageSelector] = useState(
    params.get("basestorage") ? { id: encodeURIComponent(params.get("basestorage")) } : userFacility,
  );

  const [baseStorage, setBaseStorage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [search, setSearch] = useState(params.get("q") || "");
  const [reference, setReferance] = useState(params.get("reference") || "");
  const [searchValue, setSearchValue] = useState();
  const paramHistory = mylocUseHistory();
  const [errorMessage, setErrorMessage] = useState({ show: false, message: "" });

  const doSearch = () => {
    setSearchValue({ value: search });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!search) {
      params.delete("q");
    } else if (search !== params.get("q")) {
      params.set("q", search);
    }
    paramHistory.replace(pages.INVENTORIES.PATH, params);
  }, [search, paramHistory]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!reference) {
      params.delete("reference");
    } else if (reference !== params.get("reference")) {
      params.set("reference", reference);
    }
    paramHistory.replace(pages.INVENTORIES.PATH, params);
  }, [reference, paramHistory]);

  const getProvider = useCallback(
    filter => {
      if (!searchValue || !searchValue?.value) {
        const message = { skipSearch: true, show: true, message: "INVALID_SEARCH_QUERY" };
        setErrorMessage(message);
        return message;
      } else {
        setErrorMessage({ show: true, message: "" });
        if (!filter) {
          filter = {};
        }
        filter.searchValue = searchValue?.value;
        return productService.getProductsSuppliers(filter);
      }
    },
    [searchValue],
  );

  const goToReceiveDeliveryOrder = async product => {
    if (!baseStorage) {
      setError({ error: "exception", errorMessage: translate("BASE_STORAGE_MUST_BE_ASSIGNED_VALUE") });
      return;
    }

    const response = await ReceiveService.receiveDeliveries({
      action: "VALIDATE_RECEIVE",
      payload: {
        productId: product?.id,
        facilityId: baseStorage?.id,
        supplierId: product?.supplier?.id,
      },
    });

    if (response.statusCode === 204) {
      let path = pages.RECEIVE_DELIVERY_ORDER.PATH.replace(":supplier", product?.supplier?.id)
        .replace(":product", product?.productId)
        .replace(":baseStorage", baseStorage?.id);
      let type;
      if (!product?.isFixedAsset && !product?.isBatchedControlled) {
        //not batched controlled
        type = pages.RECEIVE_DELIVERY_ORDER.TYPE.NOT_LOT_CONTROLLED;
      } else if (product?.isBatchedControlled && !product?.isFixedAsset) {
        //batched controlled
        type = pages.RECEIVE_DELIVERY_ORDER.TYPE.LOT_CONTROLLED;
      } else {
        //inventory(Fixedasset) controlled
        type = pages.RECEIVE_DELIVERY_ORDER.TYPE.FIXED_ASSET_CONTROLLED;
      }

      path = path.replace(":type", type);
      history.push({
        pathname: path,
        state: { reference: reference },
      });
    }
  };
  const onKeyDown = event => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  function handleBaseStorage(selected) {
    if (selected) {
      setBaseStorage(selected);
      setBaseStorageSelector(selected);
    } else if (baseStorage && baseStorageSelector) {
      setBaseStorage(selected);
      setBaseStorageSelector(selected);
    }
  }
  const breadcrumbs = [
    {
      text: translate(pages.BASE_STORAGES.NAME),
    },
  ];

  return (
    <Page breadcrumbs={breadcrumbs} title={translate("RECEIVE_DELIVERY_ORDER_TITLE")}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setDeliveries}
        loadOnMount={false}
        errorMessage={errorMessage}
        provider={useCallback(filter => getProvider(filter), [getProvider])}
      >
        <section>
          <section className={styles.baseSettings}>
            <div>
              <FacilitySelector
                preSelected={baseStorageSelector}
                onSelect={baseStorage => handleBaseStorage(baseStorage)}
              />
            </div>
            <div className={styles.referenceSearch}>
              <InputField
                label={translate("CHOOSE_RECEIVE_DELIVERY_REFERENCE")}
                type="text"
                maxLength={60}
                value={reference}
                onChange={event => setReferance(event.target.value)}
                customCssClass={styles.reference}
              />
            </div>
          </section>
          <section className={styles.search}>
            <InputField
              label={translate("SEARCH_FOR_TO_BE_RECEIVED_ITEM")}
              value={search}
              onChange={event => setSearch(event.target.value)}
              onKeyDown={onKeyDown}
            />
            <Button type="submit" customCssClass={styles.button} onClick={() => doSearch()}>
              {translate("SEARCH")}
            </Button>
          </section>
        </section>
        {isLoading ? (
          <LoadingSpinner title="RECEIVE_DELIVERIES_LOADING" />
        ) : (
          deliveries?.length > 0 && (
            <ol className={styles.borderTop}>
              {deliveries?.map(product => (
                <li className={styles.deliveryRow} key={product.id}>
                  <div className={styles.container}>
                    <div>
                      <div className={styles.deliveryItem}>
                        <p>{product.description}</p>
                      </div>
                      <div className={styles.deliveryItem}>
                        <Label label={translate("PRODUCT_NUMBER_SHORT")} value={product.productNumber} inline />
                        <Label
                          label={translate("SUPPLIER_PRODUCT_NUMBER")}
                          value={product.supplierProductNumber}
                          inline
                        />
                      </div>
                      <div className={styles.deliveryItem}>
                        <Label label={translate("ARTICLE_CLASS")} value={product.itemClass?.label} inline />
                        <Label label={translate("SUPPLIER")} value={product.supplier?.label} inline />
                      </div>
                    </div>

                    <Button
                      type="submit"
                      customCssClass={styles.button}
                      theme={THEME.SECONDARY}
                      onClick={() => goToReceiveDeliveryOrder(product)}
                    >
                      {translate("CHOOSE")}
                    </Button>
                  </div>
                </li>
              ))}
            </ol>
          )
        )}
      </Paginate>
    </Page>
  );
};

export default ReceiveDeliveryPage;
