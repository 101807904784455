import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation, Link, generatePath } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../../language/i18n";
import { setMessage } from "../../../reducers/dialog/dialogAction";
import productService from "../../../services/product/productService";
import orderService from "../../../services/order/orderService";
import facilityService from "../../../services/facility/facilityService";
import { useTablet } from "../../../utils/viewport";
import pages from "../../../utils/pages";
import { CLIENT_TYPE } from "../../../utils/constants";
import { DELIVERY_LOCATION } from "./Components/productConstants";
import Label from "../../shared/Label/Label";

import AccordionItem from "../../shared/AccordionItem/AccordionItem";
import Page, { Layout } from "../../shared/Page/Page";
import ProductImage, { ImagePreset } from "../../../components/Products/ProductImage/ProductImage";
import { Button, THEME } from "@myloc/myloc-gui";

import ProductOrder from "./Components/ProductOrder";
import ProductMake from "./Components/ProductMake";
import InventoryList from "../../shared/InventoryList/InventoryList";

import styles from "./InventoryDetailPage.module.scss";
import Problem from "../../../assets/icons/Problem";
import OrderHistory from "../../shared/OrderHistory/OrderHistory";

const InventoryDetailPage = ({ referrer, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const isTablet = useTablet();
  const queryParams = useParams();
  const translate = useTranslate();
  const [product, setProduct] = useState(null);
  const appData = useSelector(state => state.appData);
  const [selectedStorage, setSelectedStorage] = useState(location.state?.baseStorage);
  const [allowedBaseStorages, setAllowedBaseStorages] = useState();
  const [isAllowedToOrder, setIsAllowedToOrder] = useState();
  const [receiverLocation, setReceiverLocation] = useState(
    appData?.order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE
      ? DELIVERY_LOCATION.DELIVERY
      : DELIVERY_LOCATION.BASE_STORAGE,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const receiver = useSelector(state => state.appData.order?.receiver);

  useEffect(() => {
    if (allowedBaseStorages) {
      setIsAllowedToOrder(allowedBaseStorages?.find(storage => storage.value === product?.location?.facility?.value));
    }
  }, [allowedBaseStorages, product]);

  useEffect(() => {
    facilityService.getFacilities().then(response => setAllowedBaseStorages(response.data));
  }, []);

  useEffect(() => {
    productService.getFixedAsset({ product: encodeURIComponent(queryParams.product) }).then(response => {
      setProduct(response.data);
    });
  }, [queryParams.product]);

  const onOrder = async (quantity, inventory, orderControl) => {
    const data = {
      contentType: DELIVERY_LOCATION.BASE_STORAGE.id,
      subContentType: {
        id: !orderControl ? "NONE" : orderControl,
      },
      product: {
        id: queryParams.product,
        quantity: quantity,
      },
      fromFacility: {
        category: selectedStorage?.category,
        label: selectedStorage?.value,
        id: selectedStorage?.id,
        value: selectedStorage?.value,
      },
    };

    const response = await orderService.createContent(data);

    if (response.isOk()) {
      if (!!response.data.steps?.length > 0) {
        history.push({
          pathname: pages.PRODUCT_ORDER.PATH,
          state: { contentid: response.data.id },
          search: `contentid=${response.data.id}`,
        });
      } else {
        if (appData?.order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE) {
          setMessage(translate("CART_ADDED_ITEM", { item: response.data?.product?.description }));
        } else {
          history.push({
            pathname: pages.CART.PATH,
            state: { orderId: appData?.order.id },
          });
        }
      }
    }

    return response;
  };

  const getLabel = async () => {
    setIsLoading(true);
    const response = await productService.getFixedAssetLabel(product.inventoryNumber);
    if (response.isOk) {
      setIsLoading(false);
      //To open in a new window if response is ok
      const newWindow = window.open(response.data.url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  const getCurrentLocation = () => {
    if (product.location?.facility?.category?.value === "1" || product.location?.facility?.category?.value === "2") {
      return (
        <>
          <p
            className={styles.text}
          >{`${product.location?.facility?.category?.label} ${product.location?.warehouse?.value} - ${product.location?.warehouse?.label}`}</p>
          <p className={styles.text}>{`${product.location?.value} - ${product.location?.label}`}</p>
        </>
      );
    } else if (product.location?.facility?.category?.value === "5") {
      return (
        <Link
          to={generatePath(pages.CLIENT_INFO.PATH, { id: product.location.warehouse.value })}
        >{`${product.location?.facility?.category?.label} - ${product.location?.facility?.label}`}</Link>
      );
    } else {
      return <p className={styles.text}>{product.location?.facility?.label}</p>;
    }
  };

  const canBeReserved = () => {
    if (
      product.location?.isAllocatable &&
      product.quantity - product.allocatedQuantity > 0 &&
      product.location?.facility?.category?.value === "2" &&
      isAllowedToOrder
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getOrderNumbers = () => {
    if (product.allocations) {
      let orderNumbers = [];
      product.allocations.map(allocation => {
        if (allocation.referencedOrderLine.orderType == "WB") {
          orderNumbers.push(allocation.referencedOrderLine.orderNumber);
        }
      });

      if (orderNumbers.length)
        return (
          <>
            <span className={styles.text}>{translate("RESERVED_FOR_ORDER")}: </span>
            <span className={styles.textTitle}>{`${orderNumbers.join(", ")}`}</span>
          </>
        );
      else return translate("ORDERS_RESERVED");
    }
  };

  const breadcrumbs = () => {
    return [
      {
        text: referrer?.match(/^\/inventories/) ? translate(pages.INVENTORIES.NAME) : translate("STOCK_BALANCE"),
        link: referrer,
      },
      {
        text: `${translate("INVENTORY")} ${product.inventoryNumber}`,
      },
    ];
  };

  if (!product) {
    return <></>;
  }

  return (
    <Page breadcrumbs={breadcrumbs()} customCssClass={styles.productDetail} layout={Layout.ONE_TO_ONE} spaceBetween>
      <section className={styles.imageSection}>
        {isTablet && <h2 className={styles.title}>{product.description} </h2>}
        <ProductImage images={product.images} />

        {product.accessories?.map(accessory => {
          return (
            <div key={accessory.id} className={styles.accessories}>
              <div>
                <h3>{translate("ACCESSORIES")}</h3>
              </div>
              <div className={styles.accessory}>
                <div className={styles.image}>
                  <ProductImage images={accessory.images} preset={ImagePreset.ICON} className={styles.productImage} />
                </div>
                <div className={styles.details}>
                  <div className={styles.head}>
                    <div className={styles.left}>{accessory.description}</div>
                    <div className={styles.right}>
                      {accessory.quantity} {accessory.unit}
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.left}>
                      <div className={styles.row}>
                        <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={accessory?.productNumber} />
                      </div>

                      <div className={styles.row}>
                        <Label
                          inline
                          label={translate("ARTICLE_CLASS")}
                          value={`${accessory.itemClass?.label || "-"}`}
                        />
                      </div>
                    </div>

                    <div className={styles.right}>
                      <div className={styles.row}>
                        <Label
                          inline
                          label={translate("INVENTORY_NUMBER_SHORT")}
                          value={`${accessory?.inventoryNumber || "-"}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      {isTablet ? (
        <section className={styles.information}>
          {canBeReserved() ? (
            <ProductOrder
              selectedStorage={selectedStorage}
              onSelectStorage={setSelectedStorage}
              receiverLocation={receiverLocation}
              onSetReceiverLocation={setReceiverLocation}
              disabled={!product.location?.isAllocatable}
              onClick={onOrder}
              {...props}
            />
          ) : (
            <span>{getOrderNumbers()}</span>
          )}
          {props?.isInventory && !!selectedStorage && (
            <InventoryList
              productId={props.id}
              facilityId={selectedStorage.id}
              onOrder={onOrder}
              disabled={receiverLocation.id !== DELIVERY_LOCATION.BASE_STORAGE.id}
            />
          )}
          <div className={styles.divider}>
            <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={product.productNumber} />
            <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={product.inventoryNumber} />
            <Label inline label={translate("ARTICLE_CLASS")} value={product.itemClass?.label} />
            <Label inline label={translate("BATCH_NO")} value={product.batch} />
            <Label inline label={translate("ISO_CODE")} value={product.isoCode?.value} />
            <Label inline label={translate("SERIAL_NUMBER_SHORT")} value={product.serialNumber} />
            <Label
              inline
              label={translate("CUSTOMIZED")}
              value={product.customizationNumber ? translate("YES") : translate("NO")}
            />
            <Label inline label={translate("PRODUCT_OWNER")} value={product.goodsOwner?.label} />
            <Label
              inline
              label={translate("SALES_PRICE")}
              value={`${product.salesPrice} ${product.salesPriceCurrency?.value?.toUpperCase() ?? "SEK"}`}
            />
            <Label inline label={translate("SUPPLIER")} value={product.supplier?.label} />
            <Label inline label={translate("LAST_SERVICED")} value={product.serviceDate} />
            <Label inline label={translate("ACQUISITION_DATE")} value={product.acquisitionDate} />
            <Label inline label={translate("LAST_INSPECTION_DATE")} value={product.inspectionDate} />
            <Label inline label={translate("WARRANTY_TO_DATE")} value={product.warrantyToDate} />
            <Label label={translate("CURRENT_LOCATION")} value={getCurrentLocation()} />

            <p className={styles.text}>{product.text}</p>
            <div className={styles.printLabel}>
              <Button onClick={() => getLabel()} theme={THEME.SECONDARY} isLoading={isLoading}>
                {translate("PRINT_LABEL")}
              </Button>
            </div>
          </div>
          <AccordionItem title={translate("CUSTOMIZATION")}>
            <ProductMake makes={product.makes} />
          </AccordionItem>
        </section>
      ) : (
        <section className={styles.information}>
          <div className={styles.titleSection}>
            <div>
              <h2 className={styles.title}>
                {product.description} {product.hasDeviation && <Problem customCssClass={styles.deviation} />}
              </h2>
            </div>
            <span className={styles.quantity}>{`${product.quantity} ${product.unit}`}</span>
          </div>
          <div className={styles.infoGrid}>
            <div className={styles.row}>
              <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={product.productNumber} />
            </div>

            <div className={styles.row}>
              <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={product.inventoryNumber} />
            </div>

            <div className={styles.row}>
              <Label inline label={translate("ARTICLE_CLASS")} value={product.itemClass?.label} />
            </div>

            <div className={styles.row}>
              <Label inline label={translate("BATCH_NO")} value={product.batch} />
            </div>

            <div className={styles.row}>
              <Label inline label={translate("ISO_CODE")} value={product.isoCode?.value} />
            </div>
            <div className={styles.row}>
              <Label inline label={translate("SERIAL_NUMBER_SHORT")} value={product.serialNumber} />
            </div>
            <div className={styles.row}>
              <Label inline label={translate("PRODUCT_OWNER")} value={product.goodsOwner?.label} />
            </div>
            <div className={styles.row}>
              <Label
                inline
                label={translate("CUSTOMIZED")}
                value={product.customizationNumber ? translate("YES") : translate("NO")}
              />
            </div>
            <div className={styles.row}>
              <Label inline label={translate("SUPPLIER")} value={product.supplier?.label} />
            </div>
            <div className={styles.row}>
              <Label
                inline
                label={translate("SALES_PRICE")}
                value={`${product.salesPrice} ${product.salesPriceCurrency?.value?.toUpperCase() ?? "SEK"}`}
              />
            </div>
          </div>
          <div className={styles.infoGrid}>
            <div className={styles.row}>
              <div>
                <Label inline label={translate("ACQUISITION_DATE")} value={product.acquisitionDate} />
              </div>
              <div>
                <Label inline label={translate("LAST_SERVICED")} value={product.serviceDate} />
              </div>
              <div>
                <Label inline label={translate("LAST_INSPECTION_DATE")} value={product.inspectionDate} />
              </div>
              <div>
                <Label inline label={translate("WARRANTY_TO_DATE")} value={product.warrantyToDate} />
              </div>
            </div>
            <div className={styles.location}>
              <Label inline label={translate("CURRENT_LOCATION")} value={getCurrentLocation()} />
            </div>
          </div>
          <p className={classNames(styles.text, styles.informationMargin)}>{product.extendedDescription}</p>
          <ProductMake makes={product.makes} />
          <div className={styles.buttons}>
            <Button onClick={() => getLabel()} theme={THEME.SECONDARY} isLoading={isLoading}>
              {translate("PRINT_LABEL")}
            </Button>
            <Button theme={THEME.PLAIN_PRIMARY} onClick={() => setVisible(true)}>
              {translate("SHOW_ORDER_HISTORY")}
            </Button>
          </div>
          {canBeReserved() ? (
            receiver?.type !== CLIENT_TYPE.BASE_STORAGE && (
              <ProductOrder
                selectedStorage={selectedStorage}
                onSelectStorage={setSelectedStorage}
                receiverLocation={receiverLocation}
                onSetReceiverLocation={setReceiverLocation}
                onClick={onOrder}
                messageBoxText={translate("NO_CLIENT_ORDER_SELECTED")}
                disabled={!product.location?.isAllocatable}
                inventoryBaseStorage={`${product.location?.warehouse?.value} - ${product.location?.warehouse?.label}`}
                {...props}
              />
            )
          ) : (
            <span className={styles.reservedInfo}>{getOrderNumbers()}</span>
          )}
        </section>
      )}
      <OrderHistory visible={visible} onClose={() => setVisible(false)} id={product.id} />
    </Page>
  );
};

InventoryDetailPage.propTypes = {
  params: PropTypes.object,
  id: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  productNumber: PropTypes.string,
  isInventory: PropTypes.bool,
  orderControl: PropTypes.string,
  unit: PropTypes.string,
  quantityDecimals: PropTypes.number,
  quantity: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  storageBalances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ),
  makes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  accessories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          default: PropTypes.bool,
        }),
      ),
    }),
  ),
  referrer: PropTypes.string,
};

export default withPage(InventoryDetailPage);
