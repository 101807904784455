import { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { MultiselectField, Button } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import { DefaultItemText } from "../Text/TextComponents";
import styles from "./Accessories.module.scss";
import { useParams } from "react-router";

function Accessories({ onNext }) {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [accessories, setAccessories] = useState([]);
  const params = useParams();

  useEffect(() => {
    orderService
      .getAccessories(params.order, params.content)
      .then(response => setAccessories(response.data.accessories));
  }, [params]);

  const selectedValues = useRef({});

  const handleOnSelect = useCallback((fieldId, selected) => {
    selectedValues.current = Object.assign(selectedValues.current, { [fieldId]: selected });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const reqBody = {
      accessories: Object.entries(selectedValues.current).map(pair => {
        return { id: pair[0], selected: pair[1] };
      }),
    };

    const response = await orderService.updateAccessories(params.order, params.content, reqBody);

    setLoading(false);
    if (response.isOk()) {
      onNext(response.data);
    }
  };

  const template = item => {
    return (
      <p className={styles.item}>
        {item.value} {item.default && <DefaultItemText />}
      </p>
    );
  };

  const renderSelections = () => {
    return (
      accessories &&
      accessories.map(item => (
        <MultiselectField
          key={item.id}
          selection={item.values}
          label={item.label}
          preSelected={item.selected}
          onSelect={val => handleOnSelect(item.id, val)}
          required
          listTemplate={template}
        />
      ))
    );
  };

  return (
    <div className={styles.accessories}>
      {renderSelections()}
      <Button onClick={handleSubmit} isLoading={isLoading} customCssClass={styles.submitBtn}>
        {translate("NEXT")}
      </Button>
    </div>
  );
}

Accessories.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default Accessories;
