import queryString from "query-string";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import storage from "../utils/store/storage";
import accountService from "../services/account/accountService";
import { REMEMBER_ME, SESSION_ID } from "../utils/store/storeVariables";
import { setCompany, setFacility, setInformation, setOrder } from "../reducers/appData/appDataActions";
import {
  setSessionId,
  setRememberMe,
  setInitialized,
  setLoginToken,
  setAuthorizationList,
  setUserHasMessageToAccept,
} from "../reducers/session/sessionAction";

const Init = () => {
  const sessionInitialized = useSelector(state => state.session?.initialized);
  const [useMylocLogin, setUseMylocLogin] = useState(true);

  useEffect(() => {
    isMylocLogin() ? initializeMylocLoginSession(getMylocLoginToken()) : setUseMylocLogin(false);
  }, []);

  useEffect(() => {
    if (!useMylocLogin && !sessionInitialized) {
      initializeStandardLoginSession();
    }
  }, [sessionInitialized, useMylocLogin]);

  return null;
};

const doLoginWithToken = async mylocLoginToken => {
  const data = {
    mylocLoginToken: mylocLoginToken,
    persistent: false,
  };
  const response = await accountService.loginWithMylocToken(data);
  if (response.data?.id) {
    const appData = await accountService.getAppData();
    setAuthorizationList(appData?.data?.authorizations);
    setInitialized();
  }
};

const mylocAuthentication = async mylocLoginToken => {
  if (mylocLoginToken) {
    setLoginToken(mylocLoginToken, false);
    await doLoginWithToken(mylocLoginToken);
  }
  return;
};
const isMylocLogin = () => {
  const mylocToken = queryString.parse(location.search).mylocLoginToken;
  return mylocToken !== undefined;
};

const getMylocLoginToken = () => {
  const mylocToken = queryString.parse(location.search).mylocLoginToken;
  return mylocToken;
};

async function initializeMylocLoginSession() {
  let query = queryString.parse(location.search);
  //If a virtualSessionId exists, use it instead of logging in again
  if (query.virtualSessionId) {
    setSessionId(encodeURIComponent(query.virtualSessionId));
    const response = await accountService.getAppData();
    setAuthorizationList(response.data.authorizations);
    setInitialized();
  } else {
    await mylocAuthentication(query.mylocLoginToken);
  }
  return;
}

async function initializeStandardLoginSession() {
  let rememberMe = await storage.loadItem(REMEMBER_ME);
  setRememberMe(!!rememberMe);

  const sessionId = await storage.loadItem(SESSION_ID);
  if (sessionId) {
    const response = await accountService.getAppData();
    if (response.isOk()) {
      if (response.data?.accessMessage) {
        setUserHasMessageToAccept(response.data.accessMessage);
      }
      setCompany({
        id: response.data.company.id,
        description: response.data.company.label,
      });

      setFacility({
        id: response.data?.facility?.id,
        description: response.data?.facility?.label,
        value: response.data?.facility?.value,
        category: response.data?.facility?.category,
      });

      setInformation(response.data.user);
      setOrder(response.data.order);
      setSessionId(response.data.id);
      setAuthorizationList(response.data.authorizations);
    } else {
      storage.saveItem(SESSION_ID, null, rememberMe);
    }
  }

  setInitialized();
}

export default Init;
