import PropType from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Paginate from "../../shared/Pagination/Paginate";
import { useState, useEffect, useCallback } from "react";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import styles from "./Tasks.module.scss";
import clientService from "../../../services/client/clientService";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";

const taskTypes = ["CH", "CA"];

const Tasks = ({ setBreadcrumbs, receiver, referrer, fromReceiverMenu }) => {
  const translate = useTranslate();
  const [tasks, setTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const id = receiver.id;

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([{ text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_TASKS.NAME)} ` }]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_TASKS.NAME)} ` },
      ]);
    }
  }, [referrer, setBreadcrumbs, translate, fromReceiverMenu]);

  const getProvider = useCallback(
    filter => {
      if (id) {
        return clientService.getTasks(id, { ...filter, taskTypes });
      } else {
        return { skipSearch: true };
      }
    },
    [id],
  );

  return (
    <div>
      <h3 className={styles.contentTitle}>{translate(pages.CLIENT_TASKS.NAME)}</h3>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setTasks}
        provider={useCallback(filter => getProvider(filter), [getProvider])}
      >
        {isLoading ? (
          <LoadingSpinner title="TASKS_LOADING" />
        ) : (
          <ul className={styles.wrapper}>
            {tasks?.map(task => (
              <li className={styles.card} key={task.id}>
                <div className={styles.header}>
                  <div className={styles.left}>
                    <div className={styles.bold}>
                      <Link
                        to={{
                          pathname: `${window.location.pathname}/${task.id}`,
                          state: {
                            path: `${window.location.pathname}`,
                            text: `${translate("SEARCH_CLIENT")} - ${translate(pages.CLIENT_TASKS.NAME)}`,
                          },
                        }}
                      >
                        {task.description}
                      </Link>
                    </div>
                    <div className={styles.row}>
                      <p className={styles.label}>{translate("ORDER_NUMBER")}:</p>
                      <p>{task.referenceOrderLine?.orderNumber}</p>
                    </div>
                  </div>

                  <div className={styles.right}>
                    <div>{task.plannedDateTime}</div>
                    <div className={classNames(styles.status, styles[`status${task.status}`])}>
                      {translate(`TASK_STATUS_${task.status}`)}
                    </div>
                  </div>
                </div>

                <div className={styles.grid}>
                  <div className={styles.item}>
                    {task.fromWarehouse && (
                      <>
                        <div className={styles.label}>{translate("FROM")}:</div>
                        <div className={styles.grow}>
                          <div>{`${task.fromWarehouse?.value} - ${task.fromWarehouse?.label}`}</div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className={styles.item}>
                    <div className={styles.label}>{translate("TO")}:</div>
                    <div className={styles.grow}>
                      <div>{`${task.to?.firstName} ${task.to?.surname}`}</div>
                      <div>{task.to?.identityNumber}</div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Paginate>
    </div>
  );
};

Tasks.propTypes = {
  setBreadcrumbs: PropType.func,
  receiver: PropType.object,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default Tasks;
