import { useHistory } from "@myloc/myloc-utils";
import { Button } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useLocation } from "react-router";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import styles from "./ProductInfo.module.scss";

function ProductInfo({ product, contentId, onNext }) {
  const translate = useTranslate();
  const history = useHistory();
  const location = useLocation();

  const clickHandler = async () => {
    const response = await orderService.updateContent(contentId, { product });

    if (response.isOk()) {
      !contentId && history.replace(location.pathname, `contentid=${response.data.id}`);
      onNext({ product });
    }
  };

  return (
    <section className={styles.productInfo}>
      <ul>
        <li>
          {translate("PRODUCT_NUMBER_SHORT")}: {product?.productNumber}
        </li>
        <li>
          {translate("QUANTITY")}: {product?.quantity} {product?.unit}
        </li>
      </ul>
      <Button onClick={clickHandler}>{translate("NEXT")}</Button>
    </section>
  );
}

ProductInfo.propTypes = {
  product: PropType.shape({
    description: PropType.string,
    productNumber: PropType.string,
    quantity: PropType.number,
    unit: PropType.string,
  }),
  contentId: PropType.string,
  onNext: PropType.func,
};

export default ProductInfo;
