import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getBalance(filters = {}) {
  const url = api.baseStorage.baseStorageBalance();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default { getBalance };
