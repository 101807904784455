import { useEffect, useState } from "react";
import PropType from "prop-types";
import { Form, Button } from "@myloc/myloc-gui";
import { useTranslate } from "../../language/i18n";
import SelectField from "../shared/SelectField/SelectField";
import orderService from "../../services/order/orderService";

import styles from "./Fitting.module.scss";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const Fitting = ({ onNext, data, setData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);

  const queryParams = useParams();
  const translate = useTranslate();
  const receiver = useSelector(state => state.appData.order?.receiver);
  const currentActiveStep = useSelector(state => state.appData.currentStep);

  useEffect(() => {
    orderService.getFittingTypes().then(response => setTypes(response.data.types));
  }, [queryParams.content]);

  const btnDisabled = !data?.fittingType?.id;
  const selectDisabled = !receiver;

  const submitHandler = async () => {
    setIsLoading(true);

    const { contentType, fittingType, product, currentStep } = data;
    const dataToSend = { contentType, fittingType, product, currentStep };
    dataToSend.currentStep = currentActiveStep;

    const response = queryParams.content
      ? await orderService.updateContent(queryParams.content, dataToSend)
      : await orderService.createContent(dataToSend);

    setIsLoading(false);

    if (response.isOk) {
      onNext(response.data);
    }
  };

  return (
    <Form onSubmit={submitHandler} className={styles.content}>
      <SelectField
        options={types}
        onSelect={({ id, value: name }) => setData({ id, name })}
        label={translate("FITTING_FUNCTION")}
        selectedId={String(data?.fittingType?.id)}
        required
        disabled={selectDisabled}
      />

      <Button type="submit" isLoading={isLoading} customCssClass={styles.submitBtn} disabled={btnDisabled}>
        {translate("NEXT")}
      </Button>
    </Form>
  );
};

Fitting.propTypes = {
  data: PropType.object,
  setData: PropType.func,
  orderId: PropType.string,
  contentId: PropType.string,
  onNext: PropType.func,
  types: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      value: PropType.value,
    }),
  ),
};

export default Fitting;
