import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import accountService from "../../services/account/accountService";

import LogisticsConfirmModal from "../shared/Modals/LogisticsConfirmModal";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import { resetMessage } from "../../reducers/dialog/dialogAction";
import { useTranslate } from "../../language/i18n";
import styles from "./ErrorModal.module.scss";

const ErrorModal = () => {
  const translate = useTranslate();
  const errorMessage = useSelector(state => state.dialog.message);
  const [isLoading, setIsLoading] = useState(false);

  const connectionInterval = async () => {
    setIsLoading(true);
    return await new Promise(() => {
      const interval = setInterval(async () => {
        const response = await accountService.getAppData();
        if (response?.statusCode === 200 || response?.statusCode === 201 || response?.statusCode === 401) {
          resetMessage();
          window.location.reload();
        }
        setIsLoading(false);
        clearInterval(interval);
      }, 1000);
    });
  };

  const getErrorMessage = () => {
    return navigator.onLine
      ? translate("SERVER_ERROR_DESCRIPTION")
      : translate("INTERNET_CONNECTION_ERROR_DESCRIPTION");
  };

  useEffect(() => {
    if (errorMessage.value) {
      connectionInterval();
    }
  }, [errorMessage]);

  if (!errorMessage?.value) {
    return null;
  }

  return (
    <LogisticsConfirmModal
      title={translate("NO_CONNECTION")}
      text={getErrorMessage()}
      visible={errorMessage.value}
      cancel={() => console.log()}
      small={false}
      showButtons={false}
      showCloseButton={false}
      customCssClass={styles.text}
      showSupportEmail={navigator.onLine}
    >
      <LoadingSpinner title={translate("TRYING_TO_RECONNECT")} isLoading={isLoading} />
    </LogisticsConfirmModal>
  );
};
ErrorModal.propTypes = {
  forgotPasswordUrl: PropTypes.string,
  registerUrl: PropTypes.string,
};

export default ErrorModal;
