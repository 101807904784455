import { useEffect, useState } from "react";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import clientService from "../../../services/client/clientService";
import ContactModal from "../../shared/AddressAndContact/ContactModal/ContactModal";
import { List, ListItem } from "./Components";
import pages from "../../../utils/pages";
import { useParams } from "react-router";
import DeleteContactModal from "./Modals/DeleteContactModal";

function Contact({ receiver, setBreadcrumbs, referrer, fromReceiverMenu }) {
  const translate = useTranslate();
  const queryParams = useParams();
  const [contactModalProps, setContactModalProps] = useState({
    visible: false,
    edit: false,
    selectedContact: null,
  });
  const [deleteModalProps, setDeleteModalProps] = useState({ visible: false, selectedContact: null });
  const [contactList, setContactList] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    clientService.getContact(queryParams.id, null, false).then(response => setContacts(response.data.contacts));
  }, [queryParams.id]);

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([{ text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_CONTACTS.NAME)} ` }]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_CONTACTS.NAME)} ` },
      ]);
    }
  }, [referrer, setBreadcrumbs, translate, fromReceiverMenu]);

  useEffect(() => {
    if (contacts) {
      setContactList(contacts);
    }
  }, [contacts]);

  const handleEditContact = contact => {
    setContactModalProps({ visible: true, edit: true, selectedContact: contact });
  };

  const handleAddNewAddress = () => {
    setContactModalProps({ visible: true, edit: false, selectedContact: null });
  };

  const handleDeleteContact = contact => {
    setDeleteModalProps({ visible: true, selectedContact: contact });
  };

  const handleModalClose = contact => {
    if (contact) {
      const list = [...contactList];
      const listIndex = list.findIndex(element => element.id == contact.id);
      if (listIndex !== -1) {
        list[listIndex] = contact;
      } else {
        list.push(contact);
      }
      setContactList(list);
    }
    setContactModalProps({ visible: false, selectedContact: null });
  };

  function closeDeleteContact() {
    setDeleteModalProps({ visible: false, selectedContact: null });
    if (contactList.includes(deleteModalProps?.selectedContact)) {
      setContactList(
        contactList.filter(function (e) {
          return e !== deleteModalProps?.selectedContact;
        }),
      );
    }
  }

  return (
    <>
      <List
        title={translate("RECEIVER_DETAIL_PAGE_CONTACT_TITLE")}
        addBtnTitle={translate("NEW_CONTACT")}
        onAddNew={handleAddNewAddress}
      >
        {contactList &&
          contactList.map(contact => (
            <ListItem
              key={contact.id}
              title={contact.relation}
              item={contact}
              onEdit={handleEditContact}
              onDelete={handleDeleteContact}
            >
              <span>
                {contact.firstName} {contact.surname}
              </span>
              <span>{contact.phoneNo}</span>
              <span>{contact.email}</span>
            </ListItem>
          ))}
      </List>
      <ContactModal
        userId={receiver?.id}
        visible={contactModalProps.visible}
        contact={contactModalProps.edit ? contactModalProps.selectedContact : null}
        onClose={handleModalClose}
      />

      <DeleteContactModal
        clientId={receiver?.id}
        contactId={deleteModalProps.selectedContact?.id === undefined ? "" : deleteModalProps.selectedContact?.id}
        visible={deleteModalProps.visible}
        onClose={() => closeDeleteContact()}
      />
    </>
  );
}

Contact.propTypes = {
  receiver: PropType.object,
  contacts: PropType.array,
  setBreadcrumbs: PropType.func,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default Contact;
