import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";

import {
  AddressSection,
  AdditionalInfoSection,
  InfoSection,
  Row,
} from "../../shared/OrderInfoSections/OrderInfoSections";

const OrderInfo = ({ orderData: { product, form, address, contact } }) => {
  const translate = useTranslate();
  return (
    <section>
      <InfoSection title={translate("PRODUCT")}>
        <Row title={translate("PRODUCT_NUMBER_SHORT")} content={product?.productNumber} />
        <Row title={translate("PRODUCT_NAME")} content={product?.description} />
        <Row title={translate("SUPPLIER")} content={product?.supplier?.label} />
        <Row title={translate("QUANTITY")} content={`${product?.quantity} Styck`} />
      </InfoSection>
      {form && <AdditionalInfoSection form={form} />}
      {(address || contact) && <AddressSection address={address} contact={contact} />}
    </section>
  );
};

OrderInfo.propTypes = {
  orderData: PropTypes.object,
  product: PropTypes.object,
  address: PropTypes.object,
  contact: PropTypes.object,
  form: PropTypes.array,
};

export default OrderInfo;
