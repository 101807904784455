import { useState } from "react";
import PropType from "prop-types";
import { InputField, Form, Button, THEME } from "@myloc/myloc-gui";
import { COMMON_PATTERN, COMMON_REGEX } from "../../../../utils/regExUtils";
import { useTranslate } from "../../../../language/i18n";
import clientService from "../../../../services/client/clientService";
import { setClient } from "../../../../reducers/appData/appDataActions";
import styles from "./UserSelector.module.scss";

const UserSelector = ({ onComplete }) => {
  const translate = useTranslate();

  const [identityNumber, setIdentityNumber] = useState("");
  const [identityError, setIdentityError] = useState();
  const [identityWarning, setIdentityWarning] = useState();
  const [isValidIdentity, setIsValidIdentity] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [isLoading, setLoading] = useState(false);

  const validateIdentityNumber = e => {
    const id = e.target.value;
    if (id && !COMMON_REGEX.IDENTITY_NUMBER_SWE.test(id)) {
      setIdentityError(translate("PERSONAL_IDENTITY_NUMBER_PATTERN"));
    }
  };

  const onChangeIdentityNumber = e => {
    setIdentityNumber(e.target.value);
    setHasSearched(false);
    setCurrentUser(null);
    const matches = COMMON_REGEX.IDENTITY_NUMBER_SWE.test(e.target.value);

    if (identityError && matches) {
      setIdentityError(undefined);
    }

    if (matches) {
      setIsValidIdentity(true);
    } else {
      setIsValidIdentity(false);
    }
  };

  async function onSearch(e, searchNavet) {
    e.preventDefault();
    setLoading(true);
    const response = await clientService.findNavetClient(identityNumber, searchNavet);
    if (response?.data?.page?.length) {
      setIdentityError(null);
      setIdentityWarning(null);
      setCurrentUser(response.data.page?.[0]);
    } else {
      setIdentityWarning(translate("CLIENT_NOT_FOUND"));
      setHasSearched(true);
    }
    setLoading(false);
  }

  return (
    <>
      <Form id="search" onSubmit={onSearch}>
        <InputField
          autoFocus
          label={translate("PERSONAL_IDENTITY_NUMBER")}
          placeholder="YYYYMMDD-XXXX"
          type={"text"}
          pattern={COMMON_PATTERN.IDENTITY_NUMBER_SWE}
          required
          value={identityNumber}
          onBlur={validateIdentityNumber}
          customInvalidMessage={identityError}
          customWarningMessage={identityWarning}
          onChange={onChangeIdentityNumber}
        />
        {!currentUser && (
          <div className={styles.buttonBase}>
            <Button disabled={!isValidIdentity} isLoading={isLoading} type="submit">
              {translate("SEARCH")}
            </Button>
            {hasSearched && (
              <Button
                theme={THEME.SECONDARY}
                disabled={!isValidIdentity}
                isLoading={isLoading}
                onClick={e => onSearch(e, true)}
              >
                {translate("SEARCH_IN_NAVET")}
              </Button>
            )}
          </div>
        )}
      </Form>
      {!!currentUser && (
        <UserData user={currentUser} setUserData={setCurrentUser} onNext={() => onComplete(currentUser.id)} />
      )}
    </>
  );
};

const UserData = ({ user, setUserData, onNext }) => {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);

  const setContactInformation = value => {
    const contactInformation = Object.assign({}, user.contactInformation, value);
    setUserData(Object.assign({}, user, { contactInformation: contactInformation }));
  };

  async function onSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const response = await clientService.updateClient(user.id, {
      contactInformation: user.contactInformation,
    });

    if (response.isOk()) {
      setClient(response.data);
      onNext();
    }
    setLoading(false);
  }

  return (
    <>
      <div className={styles.information}>
        <p className={styles.value}>
          {user.firstName} {user.surname}
        </p>
        <p className={styles.value}>{user.defaultAddress?.street}</p>
        <p className={styles.value}>
          {user.defaultAddress?.postalNo} {user.defaultAddress?.city}
        </p>
      </div>
      <Form onSubmit={onSubmit}>
        <InputField
          autoFocus
          label={translate("EMAIL")}
          type={"email"}
          value={user.contactInformation.email}
          onChange={e => setContactInformation({ email: e.target.value })}
        />
        <InputField
          label={translate("PHONE_NO")}
          type={"tel"}
          value={user.contactInformation.phoneNo}
          onChange={e => setContactInformation({ phoneNo: e.target.value })}
        />
        <div className={styles.buttonBase}>
          <Button type="submit" isLoading={isLoading}>
            {translate("NEXT")}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UserSelector;

UserSelector.propTypes = {
  onComplete: PropType.func.isRequired,
};

UserData.propTypes = {
  user: PropType.object,
  setUserData: PropType.func,
  onNext: PropType.func,
};
