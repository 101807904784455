import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, InputField, Modal, Button } from "@myloc/myloc-gui";
import clientService from "../../../../services/client/clientService";
import { useTranslate } from "../../../../language/i18n";
import isEqual from "../../../../utils/compareObjects";
import styles from "./ContactModal.module.scss";

function ContactModal({ userId, visible, contact, onClose }) {
  const translate = useTranslate();

  const [currContact, setCurrContact] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (contact) {
      setCurrContact(contact);
    }
  }, [contact]);

  const handleFieldChange = e => {
    const field = e.target;
    setCurrContact({ ...currContact, [field.name]: field.value });
  };

  const handleOnClose = addr => {
    setCurrContact(null);
    onClose(addr);
  };

  const handleSubmit = async () => {
    if (contact && isEqual(contact, currContact)) {
      handleOnClose();
    } else {
      setLoading(true);
      let response;
      if (currContact.id) {
        const contactId = currContact.id || "";
        response = await clientService.updateContact(userId, contactId, {
          relation: currContact.relation,
          firstName: currContact.firstName,
          surname: currContact.surname,
          phoneNo: currContact.phoneNo,
          email: currContact.email,
        });
      } else {
        response = await clientService.createContact(userId, {
          relation: currContact.relation,
          firstName: currContact.firstName,
          surname: currContact.surname,
          phoneNo: currContact.phoneNo,
          email: currContact.email,
        });
      }
      setLoading(false);
      if (response.isOk()) {
        handleOnClose(response.data);
      }
    }
  };

  const modalTitle = contact ? translate("EDIT_CONTACT") : translate("NEW_CONTACT");

  return (
    <Modal visible={visible} title={modalTitle} onClose={handleOnClose} customCssClass={styles.modal}>
      <div className={styles.contactModal}>
        {!contact && <p className={styles.info}>{translate("NEW_CONTACT_INFO")}</p>}
        <Form onSubmit={handleSubmit} className={styles.form}>
          <InputField
            label={translate("RELATION")}
            name="relation"
            type="text"
            value={currContact?.relation || ""}
            onChange={handleFieldChange}
            autoFocus
            required
          />
          <InputField
            label={translate("FIRST_NAME")}
            name="firstName"
            type="text"
            value={currContact?.firstName || ""}
            onChange={handleFieldChange}
            required
          />
          <InputField
            label={translate("LAST_NAME")}
            name="surname"
            type="text"
            value={currContact?.surname || ""}
            onChange={handleFieldChange}
            required
          />
          <InputField
            label={translate("PHONE_NO")}
            name="phoneNo"
            type="string"
            value={currContact?.phoneNo || ""}
            onChange={handleFieldChange}
            required
          />
          <InputField
            label={translate("EMAIL")}
            name="email"
            type="email"
            value={currContact?.email || ""}
            onChange={handleFieldChange}
          />
          <Button type="submit" isLoading={isLoading} customCssClass={styles.saveBtn}>
            {translate("SAVE")}
          </Button>
        </Form>
      </div>
    </Modal>
  );
}

ContactModal.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.bool,
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    relation: PropTypes.string,
    firstName: PropTypes.string,
    surname: PropTypes.string,
    phoneNo: PropTypes.string,
    email: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

export default ContactModal;
