import { dialogVariables } from "@myloc/myloc-utils";
import { HOLDING_POPUP, ORDER_POPUP } from "./dialogVariable";
import store from "../rootReducer";

export function setError(error) {
  store.dispatch({ type: dialogVariables.ACTION.MESSAGE_ERROR, error: error?.error, message: error?.errorMessage });
}

export function setMessage(message, type = dialogVariables.ACTION.MESSAGE_INFO) {
  store.dispatch({ type: type, message: message });
}

export function resetMessage() {
  store.dispatch({ type: dialogVariables.ACTION.RESET_MESSAGE });
}

export function openOrderPopup({ includeNavigation = true, receiverType = "" }) {
  const orderPopup = {
    visible: true,
    includeNavigation: includeNavigation,
    receiverType: receiverType,
  };

  store.dispatch({ type: ORDER_POPUP, orderPopup });
}

export function closeOrderPopup() {
  const orderPopup = {
    visible: false,
    includeNavigation: true,
    receiverType: "",
  };
  store.dispatch({ type: ORDER_POPUP, orderPopup });
}

export function openHoldingPopup() {
  const holdingPopup = {
    visible: true,
  };
  store.dispatch({ type: HOLDING_POPUP, holdingPopup });
}

export function closeHoldingPopup() {
  const holdingPopup = {
    visible: false,
  };
  store.dispatch({ type: HOLDING_POPUP, holdingPopup });
}
