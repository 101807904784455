import { useRef, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Cross } from "@myloc/myloc-gui";
import styles from "./Modal.module.scss";
import LoadingSpinner from "../Spinner/LoadingSpinner";

function Modal({ visible, ...props }) {
  const isCurrentlyVisible = useRef(visible);

  let modalRoot = document.getElementById("modal-root");

  if (!modalRoot) {
    modalRoot = document.createElement("div");
    modalRoot.setAttribute("id", "modal-root");
    document.body.appendChild(modalRoot);
  }

  useEffect(() => {
    if (isCurrentlyVisible.current !== visible) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [visible]);

  return visible && createPortal(<ModalContent {...props} />, modalRoot);
}

const ModalContent = ({
  title,
  children,
  onClose,
  isLoading,
  small,
  footerContent,
  showCloseButton = true,
  customCssClass,
  customFooterClass,
}) => {
  const elementRef = useRef();

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const escapeListener = useCallback(
    e => {
      if (e.key === "Escape") {
        handleClose();
      }
    },
    [handleClose],
  );

  const clickListener = useCallback(
    e => {
      if (!elementRef.current.contains(e.target)) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    document.addEventListener("mousedown", clickListener);
    document.addEventListener("keyup", escapeListener);

    return () => {
      document.removeEventListener("mousedown", clickListener);
      document.removeEventListener("keyup", escapeListener);
    };
  }, [clickListener, escapeListener]);

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.outerContainer, small && styles.small)}>
        <section ref={elementRef} className={classNames(styles.modal, customCssClass)}>
          {showCloseButton && (
            <button onClick={handleClose} className={styles.closeButton}>
              <Cross />
            </button>
          )}

          {title && <h1 className={styles.title}>{title}</h1>}
          {isLoading ? <LoadingSpinner /> : <div className={styles.content}>{children}</div>}

          {footerContent && <footer className={classNames(styles.footer, customFooterClass)}>{footerContent}</footer>}
        </section>
      </div>
    </div>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  customCssClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

ModalContent.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  customCssClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  small: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  footerContent: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  customFooterClass: PropTypes.string,
};

export default Modal;
