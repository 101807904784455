import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import { Button, THEME } from "@myloc/myloc-gui";
import AddModal from "./Modals/AddModal";
import ReturnModal from "./Modals/ReturnModal";
import styles from "./Holding.module.scss";
import ClientHoldingList from "../../shared/ClientHoldingList/ClientHoldingList";

function Holding({ setBreadcrumbs, referrer, fromReceiverMenu }) {
  const query = useParams();
  const translate = useTranslate();
  const [selected, setSelected] = useState([]);
  const [modal, setModal] = useState("");
  const actionsDisabled = !selected.length;
  const clientId = query.id;

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([{ text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_HOLDINGS.NAME)} ` }]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        { text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_HOLDINGS.NAME)}` },
      ]);
    }
  }, [referrer, setBreadcrumbs, translate, fromReceiverMenu]);

  const handleQuantityChange = (row, num) => {
    if (!selected) return;

    if (selected.some(item => item.id === row.id)) {
      const updatedSelected = selected.filter(item => item.id !== row.id);
      updatedSelected.push({ ...row, quantity: num });
      setSelected([...updatedSelected]);
    }
  };

  return (
    <>
      <div>
        <h3 className={styles.contentTitle}>{translate("HOLDING_LIST")}</h3>
        <ClientHoldingList
          multiSelect
          link
          onSelect={setSelected}
          selected={selected}
          clientId={clientId}
          amountChangeable
          onAmountChange={handleQuantityChange}
          referrer={referrer}
          disableAllocated
          isReceiverMenu={fromReceiverMenu}
        />

        <section className={styles.actions}>
          <div className={styles.button}>
            <Button theme={THEME.SECONDARY} onClick={() => setModal("ADD_MODAL")} disabled={actionsDisabled}>
              {translate("ADD_AS_ACCESSORY")}
            </Button>
          </div>
          <div className={styles.button}>
            <Button theme={THEME.SECONDARY} onClick={() => setModal("RETURN_MODAL")} disabled={actionsDisabled}>
              {translate("RETURN_TO_BASE_STORAGE")}
            </Button>
          </div>
        </section>
      </div>

      <AddModal
        clientId={clientId}
        visible={modal === "ADD_MODAL"}
        selectedProducts={selected}
        onClose={() => setModal("")}
      />
      <ReturnModal
        clientId={clientId}
        visible={modal === "RETURN_MODAL"}
        selectedProducts={selected}
        onClose={() => setModal("")}
      />
    </>
  );
}

Holding.propTypes = {
  receiver: PropType.object,
  productList: PropType.array,
  setBreadcrumbs: PropType.func,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default Holding;
