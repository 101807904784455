import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Label from "../Label/Label";
import styles from "./OrderHistory.module.scss";

const OrderHistoryRow = ({ row }) => {
  const translate = useTranslate();

  if (!row) return null;

  return (
    <div className={styles.results}>
      <Label label={translate("ORDER_NUMBER_HÖS")} value={row.loNumber} inline />
      <Label label={translate("DESCRIPTION")} value={row.description} inline />
      <Label label={translate("PLANNED_START_DATE")} value={row.plannedStartDate} inline />
      <Label label={translate("ORDERED_BY")} value={row.createdBy} inline />
      <Label label={translate("STATUS")} value={row.status?.label} inline />
      <Label label={translate("ACTUAL_END_DATE")} value={row.actualEndDate} inline />
    </div>
  );
};

OrderHistoryRow.propTypes = {
  row: PropType.object,
};
export default OrderHistoryRow;
