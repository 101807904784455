import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import Counter from "../../Counter/Counter";
import { ContentType, Item, ItemInfo, Row } from "../ListComponents/ListComponents";
import styles from "./Types.module.scss";

function Function({ list, onDelete, onQuantityChange, showEstimatedDelivery, readOnly }) {
  const translate = useTranslate();

  const handleQuantityChange = async (newQuantity, item) => {
    if (newQuantity !== item.function.quantity) {
      const data = { function: { ...item.function, quantity: newQuantity } };
      onQuantityChange(item.id, data);
    }
  };

  return (
    <ContentType title={translate("CART_FUNCTION_TITLE")}>
      {list.map(item => (
        <Item key={item.id} orderData={item} onDelete={onDelete} readOnly={readOnly}>
          <ItemInfo>
            <div>
              <Row title={translate("FUNCTION")} text={item.function.value} />
              <Row title={translate("FUNCTION_TYPE")} text={item.function.type.value} />
              {showEstimatedDelivery && item.deliveryDate && <Row title={translate("ETA")} text={item.deliveryDate} />}
            </div>
            {readOnly ? (
              <p className={styles.quantityText}>
                {item.function.quantity} {item.function.unit ? item.function.unit : translate("UNITS_FULL")}
              </p>
            ) : (
              <Counter
                onChange={quantity => handleQuantityChange(quantity, item)}
                min={1}
                startValue={item.function.quantity}
              />
            )}
          </ItemInfo>
        </Item>
      ))}
    </ContentType>
  );
}

Function.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      contentType: PropType.string,
      function: PropType.object,
      functionAccessories: PropType.array,
      id: PropType.string,
      make: PropType.array,
      priority: PropType.object,
      status: PropType.number,
      subContentType: PropType.object,
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  onQuantityChange: PropType.func,
  showEstimatedDelivery: PropType.bool,
  readOnly: PropType.bool,
};

export default Function;
