import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import calculatePrice from "../../../hooks/useCalculatePrice";
import { useTranslate } from "../../../language/i18n";
import { CONTENT_TYPE } from "../../../utils/constants";
import Label from "../Label/Label";

import styles from "./Price.module.scss";

const TotalPrice = ({ list }) => {
  const translate = useTranslate();
  const [totalPrice, setTotalPrice] = useState();

  const calculateTotalPrice = useCallback(() => {
    let totalPrice = 0;

    if (list[CONTENT_TYPE.PRODUCT_DELIVERY]) {
      let price = 0;
      for (let i = 0; i < list[CONTENT_TYPE.PRODUCT_DELIVERY].length; i++) {
        const currentItem = list[CONTENT_TYPE.PRODUCT_DELIVERY][i].product;

        if ((currentItem?.salesPrice ?? 0) > 0)
          price += calculatePrice(currentItem.quantity, currentItem.salesPrice, currentItem.salesPriceQuantity);
      }

      totalPrice += price;
    }

    if (list[CONTENT_TYPE.PRODUCT_PICKUP]) {
      let price = 0;
      for (let i = 0; i < list[CONTENT_TYPE.PRODUCT_PICKUP].length; i++) {
        const currentItem = list[CONTENT_TYPE.PRODUCT_PICKUP][i].product;

        if ((currentItem?.salesPrice ?? 0) > 0)
          price += calculatePrice(currentItem.quantity, currentItem.salesPrice, currentItem.salesPriceQuantity);
      }

      totalPrice += price;
    }

    setTotalPrice(totalPrice);
  }, [list]);

  const getTotalPrice = () => {
    return `${totalPrice} SEK`;
  };

  useEffect(() => {
    if (list) calculateTotalPrice();
  }, [list, calculateTotalPrice]);

  return (
    <Label
      customCssClass={styles.totalPrice}
      label={`${translate("TOTAL_PRICE")}`}
      value={`${totalPrice > 0 ? getTotalPrice() : ""}`}
      hideEmpty
      inline
    />
  );
};

TotalPrice.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default TotalPrice;
