import PropType from "prop-types";
import { withPage } from "@myloc/myloc-utils";
import pages from "../../utils/pages";
import { useTranslate } from "../../language/i18n";
import WizardPage, { getActiveStep } from "../shared/WizardPage/WizardPage";
import OrderOverview from "../shared/OrderOverview/OrderOverview";
import Accessories from "../shared/Accessories/Accessories";
import AdditionalInfo from "../shared/AdditionalInfo/AdditionalInfo";
import AddressAndContact from "../shared/AddressAndContact/AddressAndContact";
import ProductInfo from "./ProductInfo/ProductInfo";
import OrderInfo from "./OrderInfo";
import { useParams } from "react-router";
import { CONTENT_TYPE } from "../../utils/constants";
import useOrderWizard from "../../hooks/useOrderWizard";

const ProductOrderPage = ({ currentActiveStep }) => {
  const translate = useTranslate();
  const queryParams = useParams();
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));
  const { data, stepper, handleNext } = useOrderWizard(
    isEmbedded ? pages.EMBEDDED_PRODUCT_ORDER.PATH : pages.PRODUCT_ORDER.PATH,
    {
      contentType: CONTENT_TYPE.PRODUCT_DELIVERY,
    },
  );

  const wizardSteps = {
    PRODUCT: {
      title: `${data?.product?.description}`,
      titleShort: translate("PRODUCT"),
      children: data?.product && (
        <ProductInfo contentId={queryParams.content} product={data?.product} onNext={handleNext} />
      ),
    },
    ACCESSORIES: {
      title: translate("CHOOSE_ACCESSORIES"),
      titleShort: translate("ACCESSORIES"),
      children: <Accessories onNext={handleNext} />,
    },
    FORM: {
      title: translate("ADDITIONAL_INFO"),
      titleShort: translate("ADDITIONAL_INFO_SHORT"),
      children: <AdditionalInfo onNext={handleNext} />,
    },
    ADDRESS_AND_CONTACT: {
      title: translate("CHOOSE_DELIVERY_ADDRESS_AND_CONTACT"),
      titleShort: translate("ADDRESS"),
      children: <AddressAndContact onNext={handleNext} />,
    },
    OVERVIEW: {
      title: translate("ORDER_OVERVIEW"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: (
        <OrderOverview>
          <OrderInfo />
        </OrderOverview>
      ),
    },
  };

  return (
    <WizardPage
      title={translate(pages.PRODUCT_ORDER.NAME)}
      titleDescription={`${data?.product?.description}`}
      steps={data?.steps && data?.steps.map(step => wizardSteps[step])}
      activeStep={getActiveStep(queryParams.content, currentActiveStep, data?.currentStep)}
      setActiveStep={step => stepper.setActiveStep(step)}
    />
  );
};

ProductOrderPage.propTypes = {
  search: PropType.string,
  currentActiveStep: PropType.number,
};

export default withPage(ProductOrderPage);
