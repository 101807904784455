import { useHistory } from "react-router-dom";
import PropType from "prop-types";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../language/i18n";
import { Button, THEME } from "@myloc/myloc-gui";
import taskService from "../../../services/taskService";
import pages from "../../../utils/pages";
import Checkbox from "../../shared/Checkbox/Checkbox";
import Counter from "../../shared/Counter/Counter";
import ConfirmModal from "../../shared/Modals/ConfirmModal";
import styles from "./TaskDetails.module.scss";

const STATUS_COMPLETED = 90;
const ACTION = { RETURN: "RETURN", HAND_OVER: "HAND_OVER" };

const TaskTypeCH = ({ task }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [selection, setSelection] = useState([]);
  const [selectedAllocations, setSelectedAllocations] = useState([]);
  const [modal, setModal] = useState(null);
  const disabled = !selection.length;

  useEffect(() => {
    if (selection.length) {
      let allocationData = [];
      selection.forEach(selection => {
        const lineAllocations = task.allocations?.filter(
          allocation => allocation.taskOrderLine.id === selection.line.id,
        );

        let count = 0;
        const allocationsQuantity = lineAllocations
          .map(allocation => {
            if (count === selection.quantity) return;
            if (count + allocation.quantity <= selection.quantity) {
              count = count + allocation.quantity;
              return { allocation, quantity: allocation.quantity };
            } else if (count + allocation.quantity > selection.quantity) {
              const diff = selection.quantity - count;
              if (diff) {
                return { allocation, quantity: diff };
              } else {
                return { allocation, quantity: selection.quantity };
              }
            }
          })
          .filter(allocation => allocation);

        allocationData.push(...allocationsQuantity);
      });
      setSelectedAllocations([...allocationData]);
    } else {
      setSelectedAllocations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const removeLine = line => {
    setSelection(selection.filter(item => item.line.id !== line.id));
  };

  const addLine = line => {
    setSelection([...selection, { quantity: line.maxQuantity, line }]);
  };

  const handleCheck = line => {
    isSelected(line) ? removeLine(line) : addLine(line);
  };

  const allSelected = () => {
    const linesWithQuantity = task.lines.filter(line => line.maxQuantity > 0);
    return selection.length === linesWithQuantity?.length;
  };

  const disableSelectAll = lines => {
    const totalMaxQuantity = lines.reduce((sum, line) => sum + line.maxQuantity, 0);
    return totalMaxQuantity < 1;
  };

  const isSelected = row => selection.find(item => item.line?.id === row.id);

  const toggleAll = () => {
    const linesWithQuantity = task.lines.filter(line => line.maxQuantity > 0);
    allSelected()
      ? setSelection([])
      : setSelection(
          linesWithQuantity?.map(line => ({
            quantity: line.maxQuantity,
            line,
          })),
        );
  };

  const handleQuantityChange = (line, num) => {
    if (isSelected(line)) {
      const _selection = selection.filter(item => item.line.id !== line.id);
      _selection.push({ line, quantity: num });
      setSelection(_selection);
    }
  };

  const getQuantity = line => {
    if (isSelected(line)) {
      return line.maxQuantity;
    }
  };

  if (!task.orderNumber) {
    return <div>Loading</div>;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.bold}>{`${task.description}`}</div>
              <div className={styles.row}>
                <p className={styles.label}>{translate("ORDER_NUMBER")}:</p>
                <p>{task.referenceOrderLine.orderNumber}</p>
              </div>
            </div>

            <div className={styles.right}>
              <div>{task.plannedDateTime}</div>
              <div className={classNames(styles.status, styles[`status${task.status}`])}>
                {translate(`TASK_STATUS_${task.status}`)}
              </div>
            </div>
          </div>

          <section className={styles.allocations}>
            <div className={styles.listHead}>
              <Checkbox
                label={translate("SELECT_ALL")}
                checked={!disableSelectAll(task.lines) && allSelected()}
                onChange={toggleAll}
                disabled={disableSelectAll(task.lines)}
              />
            </div>
            <ul>
              {task.lines?.map(line => {
                const allocations = task?.allocations?.filter(allocation => allocation?.taskOrderLine?.id === line.id);
                const locations = allocations?.map(allocation => allocation.location.value);
                const disabled = line.maxQuantity === 0;

                return (
                  <li key={line.id} className={disabled && styles.disabled}>
                    <div>
                      <Checkbox
                        onChange={() => handleCheck(line)}
                        checked={!disabled && Boolean(isSelected(line))}
                        disabled={disabled}
                      />
                    </div>

                    <div className={styles.allocation}>
                      <div className={styles.titleSection}>
                        <div className={styles.title}>{line.item?.name || line.logisticsObject?.item?.name}</div>
                      </div>
                      <div className={styles.locationQuantity}>
                        <div>{`${translate("RESERVED_ON_LOCATION")} ${locations.join(", ")}`}</div>
                        <div className={styles.title}>
                          {isSelected(line) ? (
                            <Counter
                              onChange={num => handleQuantityChange(line, num)}
                              min={1}
                              max={line.maxQuantity}
                              startValue={getQuantity(line)}
                              customCssClass={classNames(styles.counter, isSelected && styles.selected)}
                            />
                          ) : (
                            `${line.maxQuantity} ${translate("UNITS")}`
                          )}
                        </div>
                      </div>
                      <div className={styles.itemInfo}>
                        <div>
                          <span className={styles.label}>{`${translate("PRODUCT_NUMBER_SHORT")}`}</span>:{" "}
                          {line.item?.itemNumber || line.logisticsObject?.item?.itemNumber}
                        </div>
                        <div>
                          <span className={styles.label}>{`${translate("ARTICLE_CLASS")}`}</span>:{" "}
                          {line.item?.itemClass?.label || line.logisticsObject?.item?.itemClass?.label}
                        </div>
                      </div>
                      <div className={styles.assetInfo}>
                        <div>
                          <span className={styles.label}>{`${translate("INVENTORY_NUMBER_SHORT")}`}</span>:{" "}
                          {line.fixedAssetNumber || line.logisticsObject?.fixedAssetNumber}
                        </div>
                        <div>
                          <span className={styles.label}>{`${translate("BATCH_NO")}`}</span>:{" "}
                          {line.item?.batch || line.logisticsObject?.item?.batch}
                        </div>
                      </div>
                      <div className={styles.quantityInfo}>
                        <div>
                          <span className={styles.label}>{translate("TASK_ORDERED")}</span>: {line?.quantity}
                        </div>
                        <div>
                          <span className={styles.label}>{translate("TASK_DELIVERED")}</span>: {line?.reportedQuantity}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>

          <section className={styles.actions}>
            <div className={styles.button}>
              <Button onClick={() => setModal("RETURN_TO_BASE_STORAGE")} theme={THEME.SECONDARY} disabled={disabled}>
                {translate("RETURN_TO_BASE_STORAGE")}
              </Button>
            </div>
            <div className={styles.button}>
              <Button onClick={() => setModal("DELIVER")} disabled={disabled}>
                {translate("DELIVER")}
              </Button>
            </div>
          </section>
        </div>
      </div>
      <ConfirmModal
        title={translate("DELIVER")}
        text={translate("TASK_DELIVER_DESCRIPTION")}
        visible={modal === "DELIVER"}
        cancel={() => setModal(null)}
        submit={() =>
          taskService
            .doAllocationTask({
              id: task.id,
              allocations: selectedAllocations,
              action: ACTION.HAND_OVER,
              task,
            })
            .then(response => {
              setModal(null);
              if (response.data.status === STATUS_COMPLETED) {
                history.replace(pages.MY_TASKS.PATH);
              } else {
                location.reload();
              }
            })
        }
      />
      <ConfirmModal
        title={translate("RETURN_TO_BASE_STORAGE")}
        text={translate("TASK_RETURN_TO_BASE_STORAGE_DESCRIPTION")}
        visible={modal === "RETURN_TO_BASE_STORAGE"}
        cancel={() => setModal(null)}
        submit={() =>
          taskService
            .doAllocationTask({
              id: task.id,
              allocations: selectedAllocations,
              action: ACTION.RETURN,
              task,
            })
            .then(response => {
              setModal(null);
              if (response.data.status === STATUS_COMPLETED) {
                history.replace(pages.MY_TASKS.PATH);
              } else {
                location.reload();
              }
            })
        }
      />
    </>
  );
};

TaskTypeCH.propTypes = {
  task: PropType.shape({
    id: PropType.string,
    description: PropType.string,
    plannedDateTime: PropType.string,
    status: PropType.number,
    orderNumber: PropType.number,
    referenceOrderLine: PropType.shape({
      orderNumber: PropType.number,
    }),
    lines: PropType.arrayOf(
      PropType.shape({
        fixedAssetNumber: PropType.string,
        quantity: PropType.number,
        maxQuantity: PropType.number,
        item: PropType.shape({
          name: PropType.string,
          itemNumber: PropType.string,
          batch: PropType.string,
          itemClass: PropType.shape({
            label: PropType.string,
          }),
        }),
      }),
    ),
    logisticsObject: PropType.arrayOf(
      PropType.shape({
        item: PropType.shape({
          name: PropType.string,
          itemNumber: PropType.string,
          batch: PropType.string,
          itemClass: PropType.shape({
            label: PropType.string,
          }),
        }),
      }),
    ),
    allocations: PropType.arrayOf(
      PropType.shape({
        quantity: PropType.number,
        taskOrderLine: PropType.shape({
          id: PropType.string,
        }),
        location: PropType.shape({
          value: PropType.string,
        }),
      }),
    ),
  }),
};

export default TaskTypeCH;
