import PropTypes from "prop-types";

const MoreHoriz = ({ color = "currentColor", customCssClass }) => (
  <svg
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
  </svg>
);

MoreHoriz.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default MoreHoriz;
