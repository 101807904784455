import { Button, InputField } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import Label from "../../shared/Label/Label";
import styles from "../ReceiveDeliveryOrderPage.module.scss";
import { useEffect, useState } from "react";
import { setError } from "../../../reducers/dialog/dialogAction";

const ReceiveProduct = ({ data, addData, batchNr = false, serialNr = false, setCurrentStep, nextStep }) => {
  const translate = useTranslate();
  const [batch, setBatch] = useState("");
  const [serialNumber, setSerialNumber] = useState("");

  useEffect(() => {
    if (data?.batchNr) {
      setBatch(data?.batchNr);
    }
    if (data?.serialNr) {
      setSerialNumber(data?.serialNr);
    }
  }, [data]);

  function goToNextStep() {
    if (batchNr && !data?.batchNr) {
      setError({ error: "exception", errorMessage: translate("BATCH_REQUIRED") });
      return;
    }

    if (serialNr && !data?.serialNr) {
      setError({ error: "exception", errorMessage: translate("SERIAL_NUMBER_REQUIRED") });
      return;
    }

    setCurrentStep(nextStep);
  }

  function canGoToNextStep() {
    if (batchNr && !data?.batchNr) return true;
    if (serialNr && !data?.serialNr) return true;

    return false;
  }

  return (
    <article className={styles.container}>
      <section className={styles.section}>
        <Label label={translate("PRODUCT_NUMBER_SHORT")} value={data?.product?.productNumber} inline />
        <Label label={translate("SUPPLIER")} value={data?.product?.supplier?.label} inline />
        {data?.amount && <Label label={translate("AMOUNT")} value={data?.amount} inline />}

        <div className={styles.input}>
          {batchNr && (
            <InputField
              label={translate("BATCH_NO") + "*"}
              value={batch}
              onChange={event => addData("batchNr", event.target.value)}
            />
          )}
        </div>
        <div className={styles.input}>
          {serialNr && (
            <InputField
              label={translate("SERIAL_NUMBER_SHORT") + "*"}
              value={serialNumber}
              onChange={event => addData("serialNr", event.target.value)}
            />
          )}
        </div>
      </section>
      <Button type="submit" disabled={canGoToNextStep()} customCssClass={styles.button} onClick={() => goToNextStep()}>
        {translate("NEXT")}
      </Button>
    </article>
  );
};

ReceiveProduct.propTypes = {
  data: PropType.object,
  addData: PropType.func,
  batchNr: PropType.bool,
  serialNr: PropType.bool,
  setCurrentStep: PropType.func,
  nextStep: PropType.number,
};

export default ReceiveProduct;
