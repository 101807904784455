import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { SelectField } from "@myloc/myloc-gui";
import facilityService from "../../../services/facility/facilityService";
import { useTranslate } from "../../../language/i18n";
import { useEffect, useState } from "react";

const FacilitySelector = ({ onSelect, preSelected, disabled, showHUL, customCssClass, referencedPrescriber }) => {
  const translate = useTranslate();
  const appDataUser = useSelector(state => state.appData.user);
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    facilityService
      .getFacilities(decodeURIComponent(referencedPrescriber))
      .then(response => setStorages(response.data));
  }, [referencedPrescriber]);

  const getPreSelected = () => {
    if (preSelected !== null && preSelected?.category?.value !== "0") {
      if (preSelected?.category?.value === "2") {
        return preSelected.id;
      } else {
        return preSelected?.id ? preSelected.id : preSelected;
      }
    }
    return null;
  };

  return (
    <SelectField
      selection={
        storages?.length &&
        storages?.map(current =>
          Object.assign(current, {
            id: current?.id,
            value: current?.value,
            label: current?.label,
            category: current?.category,
            description: current?.address?.description,
            facilityName: `${current?.value} - ${current?.label}`,
          }),
        )
      }
      displayValue={"facilityName"}
      onSelect={onSelect}
      label={showHUL ? appDataUser.company.id : translate("BASE_STORAGE")}
      noResultLabel={translate("FACILITY_MISSING")}
      preSelected={getPreSelected()}
      autocomplete
      disabled={disabled}
      customCssClass={customCssClass}
    />
  );
};

FacilitySelector.propTypes = {
  onSelect: PropTypes.func,
  preSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  showHUL: PropTypes.bool,
  customCssClass: PropTypes.string,
  referencedPrescriber: PropTypes.string,
};

export default FacilitySelector;
