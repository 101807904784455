import {
  REMEMBER_ME as STORE_REMEMBER_ME,
  SESSION_ID as STORE_SESSION_ID,
  LOGIN_TOKEN as STORE_LOGIN_TOKEN,
} from "../../utils/store/storeVariables";
import { LOGIN_TOKEN, REMEMBER_ME, AUTHORIZATION_LIST, USER_HAS_MESSAGE_TO_ACCPEPT } from "./sessionVariables";
import storage from "../../utils/store/storage";
import store from "../rootReducer";
import { sessionVariables } from "@myloc/myloc-utils";

// Action creators
export function setSessionId(sessionId, rememberMe) {
  saveItem(STORE_SESSION_ID, sessionId, rememberMe);
  store.dispatch({ type: sessionVariables.ACTION.SESSION_ID, sessionId });
}
export function setAuthorizationList(authorizationList) {
  store.dispatch({ type: AUTHORIZATION_LIST, authorizationList });
}

export function setRememberMe(rememberMe) {
  saveItem(STORE_REMEMBER_ME, rememberMe, rememberMe);
  return { type: REMEMBER_ME, rememberMe };
}
export function setLoginToken(loginToken, rememberMe) {
  saveItem(STORE_LOGIN_TOKEN, loginToken, rememberMe);
  store.dispatch({ type: LOGIN_TOKEN, loginToken });
}

export function resetSession() {
  let state = store.getState();
  const rememberMe = state.session.rememberMe;
  storage.saveItem(STORE_SESSION_ID, null, rememberMe);
  const sessionId = null;
  return setSessionId(sessionId, false);
}

export function setInitialized() {
  store.dispatch({ type: sessionVariables.ACTION.INITIALIZED });
}

export function setUserHasMessageToAccept(message) {
  store.dispatch({ type: USER_HAS_MESSAGE_TO_ACCPEPT, message });
}

function saveItem(variableId, value, rememberMe) {
  storage.saveItem(variableId, value, rememberMe);
}
