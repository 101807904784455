import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import classNames from "classnames";
import { useHistory } from "@myloc/myloc-utils";
import { InputField } from "@myloc/myloc-gui";
import { useTranslate } from "../../language/i18n";
import { useTablet } from "../../utils/viewport";
import baseStorageService from "../../services/baseStorage/baseStorageService";
import { CLIENT_TYPE } from "../../utils/constants";
import pages from "../../utils/pages";
import Problem from "../../assets/icons/Problem";
import Label from "../shared/Label/Label";
import Page from "../shared/Page/Page";
import FacilitySelector from "../shared/FacilitySelector/FacilitySelector";
import Paginate from "../shared/Pagination/Paginate";
import styles from "./BaseStoragesPage.module.scss";
import useDebounce from "../../hooks/useDebounce";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";

const BaseStoragesPage = () => {
  const history = useHistory();
  const translate = useTranslate();
  const isTablet = useTablet();
  const userFacility = useSelector(state => state.appData?.user?.facility);
  const orderReceiver = useSelector(state => state?.appData?.order?.receiver);
  const params = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [baseStorage, setBaseStorage] = useState({
    id: params.get("storage") ? params.get("storage") : userFacility?.category?.value > 0 ? userFacility?.id : null,
  });
  const [search, setSearch] = useState(params.get("q") || "");
  const debouncedSearchValue = useDebounce(search);
  const [stock, setStock] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(true);
  const [filters, setFilters] = useState({
    freeTextSearch: debouncedSearchValue,
    baseStorage: baseStorage?.id ? decodeURIComponent(baseStorage?.id) : null,
  });
  const hideLinks =
    !baseStorage.id || (orderReceiver?.type === CLIENT_TYPE.BASE_STORAGE && orderReceiver?.id !== baseStorage?.id);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!baseStorage?.id) {
      params.delete("storage");
    } else if (baseStorage?.id && params.get("storage") !== baseStorage.id) {
      params.set("storage", baseStorage.id);
    } else if (!params.get("storage")) {
      if (userFacility?.category?.value > 0) {
        params.set("storage", userFacility.id);
      }
    }

    if (!debouncedSearchValue) {
      params.delete("q");
    } else if (debouncedSearchValue !== params.get("q")) {
      params.set("q", debouncedSearchValue);
    }

    history.replace(pages.BASE_STORAGES.PATH, params);
  }, [userFacility, baseStorage, debouncedSearchValue, history]);

  const breadcrumbs = [
    {
      text: translate(pages.BASE_STORAGES.NAME),
    },
  ];

  const baseStorageId = baseStorage?.id;

  const fullSearch = useCallback(() => {
    setFilters({
      freeTextSearch: debouncedSearchValue,
      baseStorage: baseStorageId ? decodeURIComponent(baseStorageId) : null,
    });
  }, [debouncedSearchValue, baseStorageId]);

  useEffect(() => {
    fullSearch();
  }, [debouncedSearchValue, baseStorage, fullSearch]);

  const handleChange = useCallback(baseStorage => {
    if (baseStorage) {
      setBaseStorage(baseStorage);
    } else {
      setBaseStorage({});
    }
  }, []);

  const sortedStock = stock?.length
    ? stock.sort((a, b) => {
        if (a?.product?.description > b?.product?.description) return 1;
        if (a?.product?.description < b?.product?.description) return -1;
        return 0;
      })
    : [];

  const getProvider = useCallback(
    async filter => {
      if (filters?.baseStorage !== null) {
        if (showErrorMessage) setShowErrorMessage(true);
        return baseStorageService.getBalance({
          ...filters,
          ...filter,
        });
      } else {
        setShowErrorMessage(false);
      }
    },
    [showErrorMessage, filters],
  );

  return (
    <Page title={translate("STOCK_BALANCE")} breadcrumbs={breadcrumbs}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setStock}
        provider={useCallback(filter => getProvider(filter), [getProvider])}
        errorMessage={{ show: showErrorMessage }}
        loadOnMount={!!baseStorage?.id}
      >
        <section>
          <section className={styles.search}>
            <FacilitySelector preSelected={baseStorage} onSelect={handleChange} />
            <div className={styles.searchAndButtonWrapper}>
              <InputField
                label={translate("SEARCH_PRODUCT_NR_OR_NAME")}
                value={search}
                onChange={event => setSearch(event.target.value)}
                customCssClass={styles.noErrorField}
              />
            </div>
          </section>
        </section>
        {sortedStock.length > 0 && (
          <section className={styles.results}>
            {isLoading ? (
              <LoadingSpinner title="PRODUCTS_LOADING" />
            ) : (
              sortedStock.map(stockItem => {
                return (
                  <article className={styles.item} key={stockItem.id}>
                    <section className={classNames(styles.section, stockItem.deviation && styles.titleDeviation)}>
                      <div>
                        <p className={styles.itemTitle}>
                          {stockItem?.product?.description || stockItem?.product?.id}
                          {stockItem?.product?.hasDeviation && <Problem customCssClass={styles.deviation} />}
                        </p>
                      </div>
                      {!isTablet && (
                        <div className={styles.itemBalance}>
                          <Label
                            label={translate("STOCK_BALANCE")}
                            value={stockItem?.product?.quantity + " " + stockItem?.product?.unit}
                            inline
                          />
                        </div>
                      )}
                    </section>
                    <section className={styles.section}>
                      <div>
                        {hideLinks ? (
                          <Label
                            label={translate("PRODUCT_NUMBER_SHORT")}
                            value={stockItem?.product?.productNumber}
                            inline
                          />
                        ) : (
                          <Link
                            to={{
                              pathname: generatePath(pages.PRODUCT_DETAIL.PATH, {
                                product: stockItem?.product?.productId,
                              }),
                              state: { referrer: `${window.location.pathname}${window.location.search}` },
                            }}
                          >
                            <Label
                              label={translate("PRODUCT_NUMBER_SHORT")}
                              value={stockItem?.product?.productNumber}
                              inline
                            />
                          </Link>
                        )}
                        <Label label={translate("ARTICLE_CLASS")} value={stockItem?.product?.itemClass?.label} inline />
                      </div>
                      <div>
                        {hideLinks || !stockItem?.product?.inventoryNumber ? (
                          <Label
                            label={translate("INVENTORY_NUMBER_SHORT")}
                            value={stockItem?.product?.inventoryNumber}
                            inline
                          />
                        ) : (
                          <Link
                            to={{
                              pathname: generatePath(pages.FIXED_ASSET.PATH, {
                                baseStorage: baseStorage.id,
                                product: stockItem?.product?.id,
                              }),
                              state: {
                                baseStorage: baseStorage,
                                referrer: `${window.location.pathname}${window.location.search}`,
                              },
                            }}
                          >
                            <Label
                              label={translate("INVENTORY_NUMBER_SHORT")}
                              value={stockItem?.product?.inventoryNumber}
                              inline
                            />
                          </Link>
                        )}
                        <Label label={translate("BATCH_NO")} value={stockItem?.product?.batch} inline />
                      </div>
                      <div>
                        <Label label={translate("STORAGE_PLACE")} value={stockItem?.location?.value} inline />
                        <Label label={translate("SUPPLIER")} value={stockItem?.product?.supplier?.label} inline />
                      </div>
                      <div>
                        {isTablet && (
                          <Label
                            label={translate("STOCK_BALANCE")}
                            value={stockItem?.product?.quantity + " " + stockItem?.product?.unit}
                            inline
                          />
                        )}
                        <Label label={translate("RESERVED")} value={stockItem?.product?.allocatedQuantity} inline />
                        <Label
                          label={translate("AVAILABLE_BALANCE")}
                          value={stockItem?.product?.quantity - stockItem?.product?.allocatedQuantity}
                          inline
                        />
                      </div>
                    </section>
                  </article>
                );
              })
            )}
          </section>
        )}
      </Paginate>
    </Page>
  );
};

export default BaseStoragesPage;
