import { HeaderOptions, HEADER, RestOptions } from "@myloc/myloc-utils";
import i18n from "../../language/i18n";
import storage from "../../utils/store/storage";
import { SESSION_ID } from "../../utils/store/storeVariables";
import { handleError } from "../error/errorService";

/**
 * Pass a custom Error handler function when needed - see customErrorHandler example in accountService.resetPassword
 * @param {*} customErrorHandler
 * @returns RestOptions
 */
const defaultRestOptions = async customErrorHandler => {
  const sessionId = await storage.loadItem(SESSION_ID);
  const options = new RestOptions();

  if (customErrorHandler) {
    options.errorService = customErrorHandler;
  } else {
    options.errorService = handleError;
  }

  options.headerOptions = new HeaderOptions();
  if (sessionId) options.headerOptions.setHeader(HEADER.SESSIONID, sessionId);
  options.headerOptions.setHeader(HEADER.LANGUAGE, i18n.language);

  return options;
};

export default defaultRestOptions;
