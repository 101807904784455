import { IconButton } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import styles from "./SubPageMenu.module.scss";

const SubPageMenu = ({ links, children }) => {
  const translate = useTranslate();
  const screenWidth = screen.width;
  const scrollRef = useRef(null);
  const [showArrows, setShowArrows] = useState(screenWidth < scrollRef?.current?.scrollWidth);

  useEffect(() => {
    if (screenWidth >= scrollRef?.current?.scrollWidth) {
      setShowArrows(false);
    } else {
      setShowArrows(true);
    }
  }, [screenWidth]);

  const onLeftClick = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: -100,
      behavior: "smooth",
    });
  };

  const onRightClick = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: +100,
      behavior: "smooth",
    });
  };

  return (
    <nav className={styles.navigation}>
      {showArrows && (
        <IconButton onClick={onLeftClick} customCssClass={styles.left}>
          <ChevronLeft />
        </IconButton>
      )}
      <div className={classNames(styles.scrollWrapper, showArrows && styles.showArrows)} ref={scrollRef}>
        <ul>
          {links.map(link => (
            <li key={link.PATH} className={styles.navButton}>
              <NavLink activeClassName={styles.active} to={link.PATH}>
                {translate(link.NAME)}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      {showArrows && (
        <IconButton onClick={onRightClick} customCssClass={styles.right}>
          <ChevronRight />
        </IconButton>
      )}

      <div className={styles.navButton}>{children}</div>
    </nav>
  );
};

SubPageMenu.propTypes = {
  links: PropType.arrayOf(
    PropType.shape({
      PATH: PropType.string.isRequired,
      NAME: PropType.string.isRequired,
    }),
  ),
  children: PropType.node,
};

export default SubPageMenu;
