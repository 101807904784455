import { memo, useEffect, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import { setOrder } from "../../../reducers/appData/appDataActions";
import { closeOrderPopup } from "../../../reducers/dialog/dialogAction";
import orderService from "../../../services/order/orderService";
import { CLIENT_TYPE } from "../../../utils/constants";
import pages from "../../../utils/pages";
import ClientSelector from "../ClientSelector/ClientSelector";
import Modal from "../Modals/Modal";
import Facility from "./Facility/Facility";
import UserSelector from "./UserSelector/UserSelector";
import ActionList from "./ActionList/ActionList";
import styles from "./OrderPopup.module.scss";
import { useSelector } from "react-redux";

function OrderPopup() {
  const orderPopup = useSelector(state => state.dialog.orderPopup);
  const pathname = useLocation().pathname;
  const translate = useTranslate();
  const [type, setType] = useState(CLIENT_TYPE.USER);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (orderPopup?.receiverType) {
      setType(orderPopup.receiverType);
      setStep(2);
    } else {
      setStep(1);
    }
  }, [orderPopup]);

  const hideFacility =
    pathname.startsWith(generatePath(pages.PICKUP_ORDER.PATH, { order: "none" })) ||
    pathname.startsWith(generatePath(pages.PRODUCTS_EXTERNAL_ASSORTMENT.PATH, { order: "none" })) ||
    pathname.startsWith(generatePath(pages.FITTING.PATH, { order: "none" })) ||
    pathname.startsWith(generatePath(pages.WORK_ORDER.PATH, { order: "none" }));

  async function onComplete(id) {
    const response = await orderService.createOrder({
      id: id,
      type: type === CLIENT_TYPE.USER ? "CLIENT" : type === CLIENT_TYPE.BASE_STORAGE ? "FACILITY" : "",
    });

    if (response.isOk()) {
      setOrder(response.data);
      if (orderPopup?.includeNavigation) setStep(2);
      else closeModal();
    }
  }

  const closeModal = () => {
    setStep(1);
    closeOrderPopup();
  };

  return (
    <Modal
      customCssClass={styles.container}
      visible={!!orderPopup?.visible}
      title={translate(step === 1 ? "NEW_ORDER" : "NEW_ORDER_POPUP_TITLE")}
      onClose={closeModal}
    >
      {step === 1 && (
        <div className={styles.actionContainer}>
          {!hideFacility && (
            <ClientSelector customCssClass={styles.selector} defaultType={type} onChange={value => setType(value.id)} />
          )}
          {(hideFacility || type === CLIENT_TYPE.USER) && <UserSelector onComplete={onComplete} />}
          {!hideFacility && type === CLIENT_TYPE.BASE_STORAGE && <Facility onComplete={onComplete} />}
        </div>
      )}
      {step === 2 && <ActionList onClick={closeModal} receiverType={type} />}
    </Modal>
  );
}

export default memo(OrderPopup);
