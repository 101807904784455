import { Children, isValidElement, cloneElement, useState, useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import orderService from "../../../services/order/orderService";
import FileUpload from "../../shared/File/FileUpload/FileUpload";
import { Button } from "@myloc/myloc-gui";
import { clearOrder } from "../../../reducers/appData/appDataActions";
import { clearReferencedOrder } from "../../../reducers/embedded/embeddedActions";
import styles from "./OrderOverview.module.scss";
import { useSelector } from "react-redux";

/**
 * @param {node} children - Should be OrderInfo
 */

function OrderOverview({ children }) {
  const history = useHistory();
  const queryParams = useParams();
  const translate = useTranslate();
  const activeReferenceOrder = useSelector(state => state.embedded?.referencedOrder);
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [files, setFiles] = useState(null);

  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));

  useEffect(() => {
    orderService.getContent({ contentId: queryParams.content }).then(response => setOrderData(response.data));
    orderService
      .getOrderContentFiles({ orderId: queryParams.order, contentId: queryParams.content })
      .then(response => setFiles(response.data.files));
  }, [queryParams.content, queryParams.order]);

  const addToBasket = async () => {
    setLoading(true);

    const response = await orderService.updateCart(queryParams.order, queryParams.content);
    setLoading(false);

    if (response.isOk()) {
      history.push(generatePath(pages.CART.PATH, { order: queryParams.order }), {
        newOrderId: queryParams.content,
      });
    }
  };

  const sendReferencedOrder = async () => {
    setLoading(true);
    const update = await orderService.updateCart(queryParams.order, queryParams.content);
    setLoading(false);
    if (update.isOk) {
      const response = await orderService.sendOrder(queryParams.order);
      if (response.isOk) {
        history.push(
          generatePath(isEmbedded ? "/embedded" + pages.MY_FITTINGS_ORDERS.PATH : pages.MY_FITTINGS_ORDERS.PATH, {
            fitting: activeReferenceOrder?.fitting?.id,
          }),
        );
        clearOrder();
        clearReferencedOrder();
      }
    }
  };

  //Add orderData to OrderInfo (child) here - to not have to repeat same load method and code in every wizard
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { orderData });
    }

    return child;
  });

  return (
    <div className={styles.overview}>
      {childrenWithProps}
      <FileUpload contentId={queryParams.content} files={files} iconSize={30} />
      {activeReferenceOrder ? (
        <Button onClick={() => sendReferencedOrder()}>{translate("CONFIRM_ORDER")}</Button>
      ) : (
        <Button isLoading={loading} onClick={addToBasket}>
          {translate("ADD_TO_CART")}
        </Button>
      )}
    </div>
  );
}

OrderOverview.propTypes = {
  children: PropTypes.node,
  files: PropTypes.array,
};

export default OrderOverview;
