import PropType from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useTranslate } from "../../../language/i18n";
import { InputField } from "@myloc/myloc-gui";
import { useMobile } from "../../../utils/viewport";
import Label from "../../shared/Label/Label";
import taskService from "../../../services/taskService";
import pages from "../../../utils/pages";
import Page from "../../shared/Page/Page";
import styles from "./Tasks.module.scss";
import TopFilter from "../../shared/TopFilter/TopFilter";
import Paginate from "../../shared/Pagination/Paginate";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";

const FILTERS = ["executionDateFrom", "executionDateTo"];
const taskTypes = ["CH", "CA"];

const getSelectedFiltersFromParams = (params, availableFilters) => {
  if (!availableFilters) return;

  return FILTERS.reduce((filters, key) => {
    return {
      ...filters,
      [key]: params.get(key) || "",
    };
  }, {});
};

const Tasks = ({ setBreadcrumbs }) => {
  const translate = useTranslate();
  const isMobile = useMobile();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);

  const defaultFilterValues = () =>
    FILTERS.reduce((c, key) => {
      return { ...c, [key]: "" };
    }, {});

  const [selectedFilters, setSelectedFilters] = useState(getSelectedFiltersFromParams(params, defaultFilterValues()));
  const [filters, setFilters] = useState({ ...selectedFilters });
  const [freeTextSearch, setFreeTextSearch] = useState(params.get("q") || "");

  const filterSearch = () => {
    setFilters({ ...selectedFilters });
    setFreeTextSearch(params.get("q") || "");
  };

  const onFilterChange = (key, value) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
  };

  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.MY_TASKS.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  const onInputClear = () => {
    setSelectedFilters(defaultFilterValues());
  };

  return (
    <Page customCssClass={styles.page} title={translate(pages.MY_TASKS.NAME)}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setTasks}
        provider={useCallback(
          filter =>
            taskService.getTasks({
              freeTextSearch,
              ...filters,
              ...filter,
              taskTypes,
              sort: "status:desc,plannedDateTime:asc",
            }),
          [filters, freeTextSearch],
        )}
      >
        <TopFilter
          onSubmit={filterSearch}
          filters={selectedFilters}
          setFilters={setSelectedFilters}
          fields={FILTERS}
          label={translate("SEARCH_FOR_TASK")}
        >
          <div className={styles.searchGroup}>
            <InputField
              type="date"
              label={translate("FROM_DATE")}
              labelAlwaysTop
              value={selectedFilters?.executionDateFrom}
              onChange={option => onFilterChange("executionDateFrom", option?.target?.value)}
              onClear={onInputClear}
            />
            <InputField
              type="date"
              label={translate("TO_DATE")}
              labelAlwaysTop
              value={selectedFilters?.executionDateTo}
              onChange={option => onFilterChange("executionDateTo", option?.target?.value)}
              onClear={onInputClear}
            />
          </div>
        </TopFilter>

        {isLoading ? (
          <LoadingSpinner title="TASKS_LOADING" />
        ) : (
          <ul className={styles.wrapper}>
            {!!tasks.length &&
              tasks?.map(task =>
                isMobile ? (
                  <li className={styles.card} key={task.id}>
                    <div className={classNames(styles.bold, styles.descriptionAndStatus)}>
                      <Link to={`${pages.MY_TASKS.PATH}/${task.id}`}>{task.description}</Link>
                      <div className={classNames(styles.status, styles[`status${task.status}`], styles.right)}>
                        {translate(`TASK_STATUS_${task.status}`)}
                      </div>
                    </div>
                    <div>{task.plannedDateTime}</div>
                    <Label label={translate("ORDER_NUMBER")} value={task.referenceOrderLine?.orderNumber} inline />
                    <div className={styles.item}>
                      {task.fromWarehouse && task.type != "CA" && (
                        <>
                          <div className={styles.label}>{translate("FROM")}:</div>
                          <div className={styles.grow}>
                            <div>{`${task.fromWarehouse?.value} - ${task.fromWarehouse?.label}`}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.item}>
                      <div className={styles.label}>{translate("TO")}:</div>
                      <div className={styles.grow}>
                        <div>{`${task.to?.firstName} ${task.to?.surname}`}</div>
                        <div>{task.to?.identityNumber}</div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className={styles.card} key={task.id}>
                    <div className={styles.header}>
                      <div className={styles.bold}>
                        <Link to={`${pages.MY_TASKS.PATH}/${task.id}`}>{task.description}</Link>
                      </div>
                      <div className={styles.row}>
                        <Label label={translate("ORDER_NUMBER")} value={task.referenceOrderLine?.orderNumber} inline />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div>{task.plannedDateTime}</div>
                      <div className={classNames(styles.status, styles[`status${task.status}`])}>
                        {translate(`TASK_STATUS_${task.status}`)}
                      </div>
                    </div>

                    <div className={styles.item}>
                      {task.fromWarehouse && task.type != "CA" && (
                        <>
                          <div className={styles.label}>{translate("FROM")}:</div>
                          <div className={styles.grow}>
                            <div>{`${task.fromWarehouse?.value} - ${task.fromWarehouse?.label}`}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.item}>
                      <div className={styles.label}>{translate("TO")}:</div>
                      <div className={styles.grow}>
                        <div>{`${task.to?.firstName} ${task.to?.surname}`}</div>
                        <div>{task.to?.identityNumber}</div>
                      </div>
                    </div>
                  </li>
                ),
              )}
          </ul>
        )}
      </Paginate>
    </Page>
  );
};

Tasks.propTypes = {
  setBreadcrumbs: PropType.func,
};

export default Tasks;
