import { Request, Method, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getCategories() {
  const url = api.deviation.categories();

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getReferences(category) {
  const url = api.deviation.references(category?.id);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function postValidation({ id }, body) {
  const url = api.deviation.references(id);

  const request = new Request(url, Method.POST);
  return await sendRequest(
    request,
    {
      action: "VALIDATE_REFERENCE",
      payload: body,
    },
    await defaultRestOptions(),
  );
}

async function getDeviations(filters = {}) {
  const url = api.deviation.deviation();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function createDeviation(body) {
  const url = api.deviation.deviation();

  const request = new Request(url, Method.POST);
  return await sendRequest(request, body, await defaultRestOptions());
}

async function uploadFile({ deviationId, data }) {
  const url = api.deviation.files(deviationId);

  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getDeviation(deviationId) {
  const url = api.deviation.deviation(deviationId);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default {
  getCategories,
  getReferences,
  postValidation,
  getDeviations,
  createDeviation,
  uploadFile,
  getDeviation,
};
