import pageContentService from "../../../services/pageContent/pageContentService";
import styles from "./ContentWelcome.module.scss";
import FileDownloadList from "../../shared/File/FileDownload/FileDownload";
import { ExternalLinkList, Target } from "../../shared/Link";
import Welcome from "./Welcome";
import { useEffect, useState } from "react";

const ContentWelcome = () => {
  const [pageContent, setPageContent] = useState({});

  useEffect(() => {
    pageContentService.getPageContent().then(response => setPageContent(response.data));
  }, []);

  return (
    <Welcome title={pageContent?.title ? pageContent.title : ""} text={pageContent?.text}>
      <h2 className={styles.sectionTitle}>{pageContent?.hyperlinkTitle}</h2>
      {pageContent?.hyperlinks && (
        <ExternalLinkList
          externalLinks={pageContent.hyperlinks}
          target={Target.NEW_TAB}
          customCssClass={styles.fileList}
        />
      )}

      <h2 className={styles.sectionTitle}>{pageContent?.fileTitle}</h2>
      {pageContent?.files && <FileDownloadList files={pageContent.files} target={Target.NEW_TAB} />}
    </Welcome>
  );
};

export default ContentWelcome;
