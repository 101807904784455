import PropType from "prop-types";
import { withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import WizardPage from "../shared/WizardPage/WizardPage";
import { useParams } from "react-router";
import ReceiveOrderProduct from "./Components/ReceiveOrderProduct";
import { useEffect, useState } from "react";
import productService from "../../services/product/productService";
import facilityService from "../../services/facility/facilityService";
import BaseStorageInfo from "../BaseStorages/Components/BaseStorageInfo";
import ReceiveOrderAmount from "./Components/ReceiveOrderAmount";
import ReceiveOrderEvaluate from "./Components/ReceiveOrderEvaluate";
import ReceiveOrderExecuter from "./Components/ReceiveOrderExecuter";
import ReceiveService from "../../services/Receive/ReceiveService";
import ReceiveOrderContinue from "./Components/ReceiveOrderContinue";
import { setError } from "../../reducers/dialog/dialogAction";
import Breadcrumbs from "../shared/Breadcrumbs/Breadcrumbs";
import styles from "./ReceiveDeliveryOrderPage.module.scss";

const ReceiveDeliveryOrderPage = reference => {
  const translate = useTranslate();
  const queryParams = useParams();
  const [data, setData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [deliveryComplete, setDeliveryComplete] = useState(false);
  const [canProceed, setCanProceed] = useState(true);
  const [inventoryNumber, setInventoryNumber] = useState();

  useEffect(() => {
    if (queryParams?.product && queryParams?.baseStorage) {
      productService.getProduct(queryParams?.product).then(productResponse => {
        if (!productResponse.isOk) {
          setError({ error: "exception", errorMessage: translate("REFERENCE_FIELD_TOO_LONG") });
          setCanProceed(false);
          return;
        }
        facilityService.getFacility(queryParams?.baseStorage).then(facilityResponse => {
          if (!facilityResponse.isOk) {
            setError({ error: "exception", errorMessage: translate("REFERENCE_FIELD_TOO_LONG") });
            setCanProceed(false);
            return;
          }

          let newData = { product: productResponse?.data, baseStorage: facilityResponse?.data };
          if (queryParams?.type === pages.RECEIVE_DELIVERY_ORDER.TYPE.FIXED_ASSET_CONTROLLED) {
            newData.amount = 1;
          }
          setData(newData);
        });
      });
    }
  }, [queryParams, translate]);

  const breadcrumbs = [
    { text: translate("MENU_DELIVERY"), link: pages.RECEIVE_DELIVERIES.PATH },
    { text: `${translate("PRODUCT")}  ${data?.product?.productNumber} ` },
  ];

  function addData(newKey, newValue) {
    if (data) {
      let newData = {};
      for (const [key, value] of Object.entries(data)) {
        newData[key] = value;
      }
      newData[newKey] = newValue;
      setData(newData);
    }
  }

  const receiveDelivery = async () => {
    let dataToSend = {};
    dataToSend.attributes = data.product?.attributes;

    if (dataToSend.attributes) {
      if (data?.Attribute1 && dataToSend.attributes.attribute1)
        dataToSend.attributes.attribute1.value = data?.Attribute1;
      if (data?.Attribute2 && dataToSend.attributes.attribute2)
        dataToSend.attributes.attribute2.value = data?.Attribute2;
      if (data?.Attribute3 && dataToSend.attributes.attribute3)
        dataToSend.attributes.attribute3.value = data?.Attribute3;
      if (data?.Attribute4 && dataToSend.attributes.attribute4)
        dataToSend.attributes.attribute4.value = data?.Attribute4;
      if (data?.Attribute5 && dataToSend.attributes.attribute5)
        dataToSend.attributes.attribute5.value = data?.Attribute5;
      if (data?.Attribute6 && dataToSend.attributes.attribute6)
        dataToSend.attributes.attribute6.value = data?.Attribute6;
      if (data?.Attribute7 && dataToSend.attributes.attribute7)
        dataToSend.attributes.attribute7.value = data?.Attribute7;
    }

    dataToSend.productId = data?.product?.id;
    dataToSend.facilityId = data?.baseStorage?.id;
    dataToSend.supplierId = data?.product?.supplier?.id;
    dataToSend.batch = data?.batchNr;
    dataToSend.serialNumber = data?.serialNr;
    dataToSend.quantity = data?.amount;
    dataToSend.reference = reference?.reference;

    let actionObject = {};
    actionObject.action = "RECEIVE";
    actionObject.payload = dataToSend;

    const response = await ReceiveService.receiveDeliveries(actionObject);
    if (response.statusCode === 200) {
      setInventoryNumber(response.data?.inventoryNumber);
      setDeliveryComplete(true);
    }
  };

  // --------------------------------------------------
  // not batched controlled
  // --------------------------------------------------
  const not_lotControlled = [
    {
      title: data?.product?.description,
      titleShort: translate("PRODUCT"),
      children: <ReceiveOrderProduct data={data} setCurrentStep={setCurrentStep} nextStep={1} />,
    },
    {
      title: translate("SELECT_RECEIVIED_DELIVERY_AMOUNT"),
      titleShort: translate("AMOUNT"),
      children: <ReceiveOrderAmount data={data} addData={addData} setCurrentStep={setCurrentStep} nextStep={2} />,
    },
    {
      title: translate("EVALUATE_RECEIVE_DELIVERY_ORDER"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: <ReceiveOrderEvaluate data={data} action={receiveDelivery} />,
    },
  ];

  // --------------------------------------------------
  // Batched controlled
  // --------------------------------------------------
  const lotControlled = [
    {
      title: data?.product?.description,
      titleShort: translate("PRODUCT"),
      children: (
        <ReceiveOrderProduct
          data={data}
          addData={addData}
          setCurrentStep={setCurrentStep}
          batchNr={true}
          nextStep={1}
        />
      ),
    },
    {
      title: translate("SELECT_RECEIVIED_DELIVERY_AMOUNT"),
      titleShort: translate("AMOUNT"),
      children: <ReceiveOrderAmount data={data} addData={addData} setCurrentStep={setCurrentStep} nextStep={2} />,
    },
    {
      title: translate("EVALUATE_RECEIVE_DELIVERY_ORDER"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: <ReceiveOrderEvaluate data={data} action={receiveDelivery} />,
    },
  ];
  // --------------------------------------------------
  // Fixed asset controlled
  // --------------------------------------------------
  const fixedAsset_Controlled = [
    {
      title: data?.product?.description,
      titleShort: translate("PRODUCT"),
      children: (
        <ReceiveOrderProduct
          data={data}
          addData={addData}
          setCurrentStep={setCurrentStep}
          batchNr={true}
          serialNr={true}
          nextStep={data?.product?.attributes ? 1 : 2}
        />
      ),
    },

    {
      title: translate("STATE_PERFORMANCE"),
      titleShort: translate("PERFORMANCE"),
      children: (
        <ReceiveOrderExecuter
          data={data}
          addData={addData}
          attributes={data?.product?.attributes}
          setCurrentStep={setCurrentStep}
          nextStep={2}
        />
      ),
    },
    {
      title: translate("EVALUATE_RECEIVE_DELIVERY_ORDER"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: <ReceiveOrderEvaluate data={data} showAttributes={true} action={receiveDelivery} />,
    },
  ];

  // --------------------------------------------------
  // Step types
  // --------------------------------------------------
  const steps = {
    [pages.RECEIVE_DELIVERY_ORDER.TYPE.NOT_LOT_CONTROLLED]: not_lotControlled,
    [pages.RECEIVE_DELIVERY_ORDER.TYPE.LOT_CONTROLLED]: lotControlled,
    [pages.RECEIVE_DELIVERY_ORDER.TYPE.FIXED_ASSET_CONTROLLED]: fixedAsset_Controlled,
  };

  return (
    <>
      {canProceed &&
        (deliveryComplete ? (
          <ReceiveOrderContinue inventoryNumber={inventoryNumber} reference={reference} />
        ) : (
          <div>
            <Breadcrumbs breadcrumbs={breadcrumbs} customCssClass={styles.breadcrumb} />
            <WizardPage
              title={translate("RECEIVE_DELIVERY_OF_PRODUCT")}
              titleDescription={`${data?.product?.description}`}
              steps={queryParams?.type && steps[queryParams?.type]}
              activeStep={currentStep}
              setActiveStep={setCurrentStep}
              requiresOrder={false}
              showUserInfo={false}
            >
              <BaseStorageInfo baseStorage={data?.baseStorage} />
            </WizardPage>
          </div>
        ))}
    </>
  );
};

ReceiveDeliveryOrderPage.propTypes = {
  reference: PropType.string,
};

export default withPage(ReceiveDeliveryOrderPage);
