import PropTypes from "prop-types";
import { useTranslate } from "../../language/i18n";
import { AddressSection, AdditionalInfoSection, InfoSection, Row } from "../shared/OrderInfoSections/OrderInfoSections";
import { DefaultItemText } from "../shared/Text/TextComponents";

function OrderInfo({ orderData: { address, contact, functionAccessories, form, product } }) {
  const translate = useTranslate();

  const productInfo = product => (
    <InfoSection title={translate("PRODUCT")}>
      <Row content={product.description} />
      <Row title={translate("PRODUCT_NUMBER_SHORT")} content={product.productNumber} />
      <Row title={translate("QUANTITY")} content={`${product.quantity} ${product.unit}`} />
    </InfoSection>
  );

  const showAccessories = data => (
    <InfoSection title={translate("ACCESSORIES")}>
      {data.map(item => (
        <Row
          key={item.id}
          title={item.label}
          content={item.values.map(val => (
            <span key={val.id}>
              {val.value} {val.default && <DefaultItemText />}
            </span>
          ))}
        />
      ))}
    </InfoSection>
  );

  return (
    <section>
      {product && productInfo(product)}
      {functionAccessories && showAccessories(functionAccessories)}
      {form && <AdditionalInfoSection form={form} />}
      {(address || contact) && <AddressSection address={address} contact={contact} />}
    </section>
  );
}

OrderInfo.propTypes = {
  orderData: PropTypes.shape({
    address: PropTypes.object,
    contact: PropTypes.object,
    functionAccessories: PropTypes.array,
    form: PropTypes.object,
    product: PropTypes.object,
  }),
};

export default OrderInfo;
