import { useState, useCallback, useEffect } from "react";
import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import { UserInformation, ContactInformation } from "../../shared/RecieverInformation/RecieverInformation";
import { THEME } from "@myloc/myloc-gui";

import clientService from "../../../services/client/clientService";
import styles from "../FittingDetailPage.module.scss";
import pages from "../../../utils/pages";
import orderService from "../../../services/order/orderService";
import CheckAuthButton from "../../shared/Buttons/CheckAuthButton";
import SelectFieldModal from "../Modals/SelectFieldModal";

const UserAndContact = ({ fitting, setFetchFittings, setBreadcrumbs, embeddedFitting }) => {
  const translate = useTranslate();
  const [contactInfo, setContactInfo] = useState(fitting?.contact);
  const [editContact, setEditContact] = useState(false);
  const [contacts, setContacts] = useState([]);
  const isCompleted = fitting.status.value === 90 || fitting.status.value === 99;

  useEffect(() => {
    setBreadcrumbs([
      {
        text: embeddedFitting?.text
          ? translate(embeddedFitting?.text)
          : `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`,
        link: embeddedFitting?.path ? embeddedFitting?.path : pages.MY_FITTINGS.PATH,
      },
      {
        text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_USER_AND_CONTACT.NAME)}`,
      },
    ]);
  }, [embeddedFitting, setBreadcrumbs, translate]);

  const onEdit = async () => {
    await clientService.getContact(fitting?.client?.id).then(response => setContacts(response.data.contacts));
    setEditContact(true);
  };

  const onSelect = newContact => {
    setContactInfo(newContact);
  };

  const handleSubmit = async () => {
    const data = {
      address: fitting?.address,
      contact: contactInfo,
    };

    const response = await orderService.updateDelivery(data, fitting?.orderId, fitting?.id);
    if (response.isOk) {
      setFetchFittings(true);
      setEditContact(false);
    }
  };

  const handleOnClose = () => {
    setContactInfo(fitting?.contact);
    setEditContact(false);
  };

  const contactTemplate = useCallback(
    item => (
      <>
        <p>{item.relation}</p>
        <p>
          {item.firstName} {item.surname}
        </p>
        <p>{item.phoneNo}</p>
        <p>{item.email}</p>
      </>
    ),
    [],
  );

  return (
    <>
      <section className={styles.split}>
        <div>
          <UserInformation receiver={fitting?.client} />
        </div>
        <div className={styles.editableContact}>
          <div>
            <ContactInformation contact={fitting?.contact} title={translate("CONTACT")} />
          </div>
          {!isCompleted && (
            <div className={styles.buttonWrapper}>
              <CheckAuthButton
                theme={THEME.PLAIN_PRIMARY}
                onClick={() => onEdit()}
                title={translate("CHOOSE_CONTACT")}
                authId="GetClientContacts"
              />
            </div>
          )}
        </div>
      </section>
      <SelectFieldModal
        visible={editContact}
        title={translate("CHOOSE_CONTACT")}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        options={contacts}
        selectedId={contactInfo?.id || fitting?.contact?.id}
        onSelect={onSelect}
        template={contactTemplate}
        display={"relation"}
        required
      />
    </>
  );
};

UserAndContact.propTypes = {
  fitting: PropType.object,
  setFetchFittings: PropType.func,
  setBreadcrumbs: PropType.func,
  embeddedFitting: PropType.object,
};

export default UserAndContact;
