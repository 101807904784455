import pages from "./pages";

export const ADDRESS_TYPE = {
  COMPANY: "COMPANY",
  CLIENT: "CLIENT",
};

export const CLIENT_TYPE = {
  USER: "CLIENT",
  BASE_STORAGE: "FACILITY",
  SECRET_ID: "Secret_ID",
};

export const CONTACT_TYPE = {
  PERSON: "PERSON",
  CLIENT: "CLIENT",
};

export const CONTENT_TYPE = {
  FUNCTION: "FUNCTION",
  PRODUCT_DELIVERY: "PRODUCT_DELIVERY",
  NEW_PRODUCT: "NEW_PRODUCT",
  PRODUCT_PICKUP: "PRODUCT_PICKUP",
  PICKUP: "PICKUP",
  FITTING: "FITTING",
  WORK: "WORK",
};

//if added to cart or in draft mode
export const CONTENT_STATUS = {
  DRAFT: 0,
  CART: 10,
  ORDERED: 20,
  PROCESSED: 30,
  IN_PROGRESS: 80,
  COMPLETED: 90,
  ANNULLED: 99,
};

export const CONTENT_PATH = {
  FUNCTION: pages.FUNCTION_ORDER.PATH,
  PRODUCT_DELIVERY: pages.PRODUCT_ORDER.PATH,
  NEW_PRODUCT: pages.PRODUCTS_EXTERNAL_ASSORTMENT.PATH,
  PICKUP: pages.PICKUP_ORDER.PATH,
  FITTING: pages.FITTING.PATH,
  WORK: pages.WORK_ORDER.PATH,
};

export const LINKED_CATEGORIES = {
  IMAGE: "LinkedImages",
  DOCUMENT: "LinkedDocuments",
  VIDEO: "LinkedVideos",
};
