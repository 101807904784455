import { memo } from "react";
import PropTypes from "prop-types";
import Enkoping from "../../../assets/logos/Enkoping.svg";
import EnkopingFull from "../../../assets/logos/Enkoping_full.svg";
import Habo from "../../../assets/logos/Habo.svg";
import HaboFull from "../../../assets/logos/Habo_full.svg";
import Heby from "../../../assets/logos/Heby.png";
import HebyFull from "../../../assets/logos/Heby_full.png";
import Knivsta from "../../../assets/logos/Knivsta.svg";
import KnivstaFull from "../../../assets/logos/Knivsta_full.png";
import Osthammar from "../../../assets/logos/Osthammar.svg";
import OsthammarFull from "../../../assets/logos/Osthammar_full.svg";
import Tierp from "../../../assets/logos/Tierp.png";
import TierpFull from "../../../assets/logos/Tierp_full.png";
import Uppsala from "../../../assets/logos/Uppsala.svg";
import UppsalaFull from "../../../assets/logos/Uppsala_full.svg";

const logos = [
  {
    id: "Enkoping",
    alt: "Enköpings kommun",
    fullLogo: EnkopingFull,
    logo: Enkoping,
  },
  {
    id: "Habo",
    alt: "Håbo kommun",
    fullLogo: HaboFull,
    logo: Habo,
  },
  {
    id: "Heby",
    alt: "Heby kommun",
    fullLogo: HebyFull,
    logo: Heby,
  },
  {
    id: "Knivsta",
    alt: "Knivsta kommun",
    fullLogo: KnivstaFull,
    logo: Knivsta,
  },
  {
    id: "Osthammar",
    alt: "Östhammar kommun",
    fullLogo: OsthammarFull,
    logo: Osthammar,
  },
  {
    id: "Tierp",
    alt: "Tierp kommun",
    fullLogo: TierpFull,
    logo: Tierp,
  },
  {
    id: "Uppsala",
    alt: "Uppsala kommun",
    fullLogo: UppsalaFull,
    logo: Uppsala,
  },
];

function MunicipalityLogo({ id, customCssClass, fullLogo = true, imgLink }) {
  if (imgLink) {
    return <embed src={imgLink} alt={id} className={customCssClass} />;
  }

  if (!id) {
    return;
  }

  const img = logos.find(logo => logo.id === id);

  let logo;
  if (imgLink) {
    logo = imgLink;
  } else if (fullLogo) {
    logo = img.fullLogo;
  } else {
    logo = img.logo;
  }

  return img ? <embed src={logo} alt={img.alt} className={customCssClass} /> : null;
}

MunicipalityLogo.propTypes = {
  id: PropTypes.string.isRequired,
  fullLogo: PropTypes.bool,
  customCssClass: PropTypes.string,
  imgLink: PropTypes.string,
};

export default memo(MunicipalityLogo);
