import { LOGIN_TOKEN, REMEMBER_ME, AUTHORIZATION_LIST, USER_HAS_MESSAGE_TO_ACCPEPT } from "./sessionVariables";

const initialState = {
  loginToken: null,
  rememberMe: null,
  sessionId: null,
  initialized: false,
  authorizationList: null,
  userMessageToAccept: null,
};

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_TOKEN:
      return {
        ...state,
        loginToken: action.loginToken,
      };
    case REMEMBER_ME: {
      const rememberMe = action.rememberMe instanceof String ? action.rememberMe === "true" : action.rememberMe;
      return {
        ...state,
        rememberMe: rememberMe,
      };
    }
    case AUTHORIZATION_LIST: {
      return {
        ...state,
        authorizationList: action.authorizationList,
      };
    }
    case USER_HAS_MESSAGE_TO_ACCPEPT: {
      return {
        ...state,
        userMessageToAccept: action.message,
      };
    }
    default:
      return state;
  }
};
