import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import pages from "../../../../utils/pages";
import logo from "../../../../assets/logos/Myloc-logga.svg";
import OrderMenu from "../OrderMenu/OrderMenu";
import ReceiverMenu from "../ReceiverMenu/ReceiverMenu";
import CartButton from "../CartButton/CartButton";
import styles from "./MobileHeader.module.scss";
import classNames from "classnames";

function MobileHeader() {
  const userLoggedIn = useSelector(state => state.session?.sessionId);
  const order = useSelector(state => state.appData.order);

  return (
    <header className={classNames(styles.header, userLoggedIn && styles.loggedIn)}>
      <section className={styles.topBar}>
        <figure>
          <Link to={pages.HOME}>
            <img src={logo} alt="Myloc logo" className={styles.logo} />
          </Link>
        </figure>
        {userLoggedIn && <CartButton />}
      </section>
      {userLoggedIn && (
        <>
          <section className={classNames(styles.actionBar, order?.receiver ? styles.displayGrid : styles.displayRight)}>
            {order?.receiver && <ReceiverMenu customCssClass={styles.receiverMenu} />}
            <div className={styles.orderMenuWrapper}>
              <OrderMenu customCssClass={styles.orderMenu} />
            </div>
          </section>
          <section className={styles.searchContainer}></section>
        </>
      )}
    </header>
  );
}

export default MobileHeader;
