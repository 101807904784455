import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import pages from "../../../../utils/pages";
import { CLIENT_TYPE, CONTENT_STATUS } from "../../../../utils/constants";
import Checkbox from "../../Checkbox/Checkbox";
import Counter from "../../Counter/Counter";
import { ORDER_CONTROL, isOrderControlMandatory } from "../../../Products/ProductDetail/Components/productConstants.js";
import { ContentType, Item, ItemInfo, Row, RowTitle } from "../ListComponents/ListComponents";
import styles from "./Types.module.scss";
import Price from "../../Price/Price";

function ProductDelivery({
  list,
  onDelete,
  onQuantityChange,
  onAssemblyInstallationChange,
  showEstimatedDelivery,
  readOnly,
  checkboxDisabled,
  isReferencedOrder,
}) {
  const translate = useTranslate();
  const order = useSelector(state => state.appData.order);
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));

  const handleQuantityChange = (newQuantity, item) => {
    if (newQuantity !== item.product.quantity) {
      const data = { product: { ...item.product, quantity: newQuantity } };
      onQuantityChange(item.id, data);
    }
  };

  const handleCheckChange = (checked, item) => {
    const checkedValue = ORDER_CONTROL[item.product.orderControl].value;
    const data = { ...item, subContentType: { id: checked ? checkedValue : ORDER_CONTROL.NONE.id } };
    onAssemblyInstallationChange(item.id, data);
  };

  return (
    <ContentType title={translate("CART_PRODUCT_DELIVERY_TITLE")}>
      {list.map(item => {
        const hiddenCheckbox =
          order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE || item.product.orderControl === ORDER_CONTROL.NONE.id;

        const checked = item.subContentType.id === "INSTALLATION" || item.subContentType.id === "ASSEMBLY";

        const checkboxLabel = translate(ORDER_CONTROL[item.product.orderControl].value);

        const disabled = isOrderControlMandatory(item.product.orderControl);

        return (
          <Item
            key={item.id}
            orderData={item}
            onDelete={onDelete}
            readOnly={readOnly}
            isEmbedded={isEmbedded}
            isReferencedOrder={isReferencedOrder}
          >
            <ItemInfo>
              <div>
                {!isEmbedded ? (
                  <Link
                    to={{
                      pathname: `${pages.PRODUCTS.PATH}/${item.product.productId}`,
                      state: item.product,
                    }}
                  >
                    <RowTitle text={item.product.description} />
                  </Link>
                ) : (
                  <RowTitle text={item.product.description} />
                )}
                <Row title={translate("PRODUCT_NUMBER_SHORT")} text={item.product.productNumber} />
                {showEstimatedDelivery && item.deliveryDate && (
                  <Row title={translate("ETA")} text={item.deliveryDate} />
                )}

                {!hiddenCheckbox && (
                  <Checkbox
                    label={checkboxLabel}
                    checked={checked}
                    disabled={disabled || readOnly || checkboxDisabled}
                    onChange={e => handleCheckChange(e.currentTarget.checked, item)}
                    customCssClass={styles.assemblyCheckbox}
                  />
                )}
              </div>
              {readOnly || (isReferencedOrder && item.status.value !== CONTENT_STATUS.DRAFT) ? (
                <p className={styles.quantityText}>
                  {item.product.quantity} {item.product.unit}
                  <Price
                    quantity={item.product?.quantity}
                    price={item.product?.salesPrice}
                    priceQuantity={item.product?.salesPriceQuantity}
                    currency={item.product?.salesPriceCurrency?.value?.toUpperCase()}
                  />
                </p>
              ) : (
                <div>
                  {(!isReferencedOrder || (isReferencedOrder && isEmbedded)) && (
                    <Counter
                      onChange={quantity => handleQuantityChange(quantity, item)}
                      min={1}
                      startValue={item.product.quantity}
                    />
                  )}
                  <Price
                    quantity={item.product?.quantity}
                    price={item.product?.salesPrice}
                    priceQuantity={item.product?.salesPriceQuantity}
                    currency={item.product?.salesPriceCurrency?.value?.toUpperCase()}
                    customCssClass={styles.price}
                  />
                </div>
              )}
            </ItemInfo>
          </Item>
        );
      })}
    </ContentType>
  );
}

ProductDelivery.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      address: PropType.object,
      contentType: PropType.string,
      product: PropType.shape({
        id: PropType.string,
        description: PropType.string,
        productNumber: PropType.string,
        quantity: PropType.number,
        supplier: PropType.string,
        orderControl: PropType.string,
      }),
      make: PropType.array,
      priority: PropType.object,
      status: PropType.number,
      subContentType: PropType.object,
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  onQuantityChange: PropType.func,
  onAssemblyInstallationChange: PropType.func,
  readOnly: PropType.bool,
  showEstimatedDelivery: PropType.bool,
  checkboxDisabled: PropType.bool,
  isReferencedOrder: PropType.object,
};

export default ProductDelivery;
