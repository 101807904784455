import PropTypes from "prop-types";
import { useTranslate } from "../../language/i18n";
import { InfoSection, Row, AddressSection, AdditionalInfoSection } from "../shared/OrderInfoSections/OrderInfoSections";
import { DefaultItemText } from "../shared/Text/TextComponents";

function OrderInfo({ orderData: { function: functionType, make, functionAccessories, form, address, contact } }) {
  const translate = useTranslate();

  const functionInfo = data => (
    <InfoSection title={translate("FUNCTION")}>
      <Row title={translate("FUNCTION")} content={data.value} />
      <Row title={translate("FUNCTION_TYPE")} content={data.type.value} />
      <Row title={translate("QUANTITY")} content={`${data.quantity} Styck`} />
    </InfoSection>
  );

  const showMake = data => (
    <InfoSection title={translate("CUSTOMIZATION")}>
      {data.map(item => (
        <Row key={item.id} title={item.label} content={item.value} />
      ))}
    </InfoSection>
  );

  const showAccessories = data => (
    <InfoSection title={translate("ACCESSORIES")}>
      {data.map(item => (
        <Row
          key={item.id}
          title={item.label}
          content={item.values.map(val => (
            <span key={val.id}>
              {val.value} {val.default && <DefaultItemText />}
            </span>
          ))}
        />
      ))}
    </InfoSection>
  );

  return (
    <section>
      {functionType && functionInfo(functionType)}
      {make && showMake(make)}
      {functionAccessories && showAccessories(functionAccessories)}
      {form && <AdditionalInfoSection form={form} />}
      {(address || contact) && (
        <AddressSection address={address} contact={contact} title="DELIVERY_ADDRESS_AND_CONTACT" />
      )}
    </section>
  );
}

OrderInfo.propTypes = {
  orderData: PropTypes.shape({
    function: PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
    make: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    functionAccessories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        values: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            default: PropTypes.bool,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    form: PropTypes.object,
    address: PropTypes.object,
    contact: PropTypes.object,
  }),
};

export default OrderInfo;
